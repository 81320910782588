import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    Typography,
   
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import store from 'store';
import update from 'store/plugins/update';
import API from '../../common/axios/api';
import { withSnackbar } from '../../common/utils/SnackBarHOC';
import BillingAddress from './components/BillingAddress';
import ShippingAddress from './components/ShippingAddress';

const PickAddress = (props,{ selectedAddressIndex }) => {
    const [expanded, setExpanded] = useState('panel2');
    const [billingAddress, setBillingAddress] = useState();
    const [shippingAddress, setShippingAddress] = useState();
    const [billingAddressData, setBillingAddressData] = useState();
    const [shippingAddressData, setShippingAddressData] = useState(undefined);
    const [ setCurrentPage] = useState();
    const [resetPages,setResetPages] = useState();
    const [checkRadio,setcheckRadio] = useState(null);
    const [ResetFormik, setResetFormik] = useState();
    const [filterValue, setFilterValue] = useState('');

    


 

    // Add the update plugin to the localstorage helper
    store.addPlugin(update);

    // Sets the border if an address is missing before moving to the next screen
    const billingRef = useRef();
    const shippingRef = useRef();
    const [billingError, setBillingError] = useState('');
    const [shippingError, setShippingError] = useState('');
    const errorStyle = '2px solid red';
    const [shippingPanelOpenCount, setShippingPanelOpenCount] = useState(0);
    const [addresses, setAddresses] = useState([]);
    const [filteredAddresses, setFilteredAddresses] = useState([]);
    const cityRef = useRef();
    const stateRef = useRef();
    const zipRef = useRef();
  

    let history = useHistory();
    const user = useSelector((state) => state.user);

    // Handle Accordian Change events
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);

        if (panel === 'panel1') {
            isBillingValid();
        } else {
            // Count doesn't check when the panel first opens; otherwise will always error out
            let openCount = shippingPanelOpenCount;
            if (openCount > 0) {
                isShippingValid();
            }
            setShippingPanelOpenCount((openCount += 1));
        }
    };


    
    // Run the ref function and sets the accordian style based on the billing address info is valid
    const isBillingValid = async () => {
        let billingValid = await billingRef.current.isValid();
        if (Object.keys(billingValid).length === 0) {
            setBillingError('');
            return true;
        } else {
            setBillingError(errorStyle);
            return false;
        }
    };

    // Run the ref function and sets the accordian style based on the billing address info is valid
    const isShippingValid = async () => {
        let shippingValid = await shippingRef.current.isValid();
        if (Object.keys(shippingValid).length === 0) {
            setShippingError('');
            return true;
        } else {
            setShippingError(errorStyle);
            return false;
        }
    };

    let uniqueStates = [...new Set(addresses.map(value => JSON.stringify({ state: value.ShipTo_State })))].map(value => JSON.parse(value));
uniqueStates.sort((a, b) => a.state.localeCompare(b.state));

  
let uniqueCities = [...new Set(addresses.map(value => JSON.stringify({ city: value.ShipTo_City })))].map(value => JSON.parse(value));
uniqueCities.sort((a, b) => a.city.localeCompare(b.city));

let uniqueZips = [...new Set(addresses.map(value => JSON.stringify({ zip: value.ShipTo_ZIP })))].map(value => JSON.parse(value));
uniqueZips.sort((a, b) => a.zip - b.zip);

    const handleSelect = (event) => {
        const selectedBox = event.target;
       
        selectedBox.parentNode.classList.add('selected');
        handleFilter();
      };


    // Try to load local storage info, and get address data from epicor
    useEffect(() => {
        // Check to see if the cart in defined
        let cart = store.get('cart');
        if (cart === undefined) {
            history.push('/home');
        } else {
            // Get localStorage info
            let bill = store.get('bill');
            let ship = store.get('ship');
            console.log(ship,"Ship")
            if (bill !== undefined) setBillingAddress(bill.billing);
            // if (ship !== undefined) setShippingAddress(ship.shipping);

            // Get User Addresses
            if (user !== null) {
                // Get Customer Billing Addresses
                let billingAddrApi = new API('SIG_API_customerID');
                billingAddrApi.setFilter(
                    `Customer_CustID eq '${user.customerId}'`
                );
                billingAddrApi
                    .get()
                    .then((response) => {
                        let billing = response.data.value;
                        console.log(billing,"billing")
                        // Added this because data doesn't support it, yet client wants it
                        for (var i = 0; i < billing.length; i++) {
                            billing[i].Customer_BTEmail = '';
                        }

                        setBillingAddressData(billing);
                    })
                    .catch((err) => console.log(err));

                // Get Customer Shipping Addresses
                const shippingApi = new API('SIG_API_customerID_ShipTo');
                shippingApi.setFilter(`Customer_CustID eq '${user.customerId}'`);
                shippingApi.get()
                  .then((response) => {
                    if (Array.isArray(response.data.value)) {
                    const addresses = response.data.value;
                    // added this because data doesn't support it, yet client wants it
                    for (var i = 0; i < addresses.length; i++) {
                        addresses[i].ShipTo_Email = '';
                        addresses[i].ShipTo_Attention = '';
                        addresses[i].SaveAddress = false;
                    }
                     
                      setResetPages(true);
                      setAddresses(addresses);
                      setFilteredAddresses(addresses);
                      console.log(addresses,"addresses")
                      setShippingAddressData(addresses);
                    } else {
                      console.error('Response data is not an array.', response.data);
                    }
                  })
                  .catch((error) => {
                    console.error('Error fetching shipping addresses.', error);
                });
            }
        }
    }, [user]);

    const handleFilter = () => {
        const selectedCity = cityRef.current.value;
        const selectedState = stateRef.current.value;
        const selectedZip = zipRef.current.value;
    
        let filtered = addresses;
    
        if (selectedCity !== '') {
          filtered = filtered.filter((value) => value.ShipTo_City === selectedCity);
        }
        if (selectedState !== '') {
            filtered = filtered.filter((value) => value.ShipTo_State === selectedState);
        }
    
        if (selectedZip !== '') {
          filtered = filtered.filter((value) => value.ShipTo_ZIP === selectedZip);
        }
        console.log(filtered,"filtered Address");
        setResetPages(true);
        setcheckRadio(false);
        setShippingAddressData(filtered);
        setShippingAddress(undefined);
        setFilterValue('')
       
      };

    useEffect(() => {
       
        setShippingAddressData(filteredAddresses);
    }, [filteredAddresses]);

    // User is about to progress to the final order screen, we must verify all information is present first
    const reviewOrder = async () => {
        // where I the validate info
        let isBill = await isBillingValid();
        let isShip = await isShippingValid();
        if (isBill && isShip) {
            // If undefined create, otherwise update
            if (store.get('bill') === undefined) {
                store.set('bill', { billing: billingAddress });
            } else {
                store.update('bill', (bill) => {
                    bill.billing = billingAddress;
                });
            }

            // If undefined create, otherwise update
            if (store.get('ship') === undefined ) {
                store.set('ship', { shipping: shippingAddress});
            } else {
                store.update('ship', (ship) => {
                    ship.shipping = shippingAddress;
                });
            }

            // Send user to review their order
            history.push('/placeorder/review');
        } else {
            // Let the user know they need to fill something out
            
            if(shippingAddress.ShipTo_Country===""){
                props.snackbarShowMessage('Shipping Country is required', 'error', 5000);
                console.log(shippingAddress,"shippingAddress");
            }
            else{
            console.log("shippingCountry")
            props.snackbarShowMessage(
                'You need to select a billing and shipping address before you can continue!',
                'error',
                5000
            );
            }
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
            
                {/* Billing */}
                <Accordion
                    expanded={expanded === 'panel1'}
                    onChange={handleChange('panel1')}
                    sx={{ border: billingError }}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography sx={{ width: '66%', flexShrink: 0 }}>
                            Select Billing Address
                        </Typography>
                        {/* Print the selected Address */}
                        {billingAddress !== undefined ? (
                            <Typography sx={{ color: 'text.secondary' }}>
                                {`${billingAddress.Customer_BTAddress1} ${billingAddress.Customer_BTAddress2} ${billingAddress.Customer_BTCity}, ${billingAddress.Customer_BTState} ${billingAddress.Customer_BTZip}, ${billingAddress.Customer_BTCountry}`}
                            </Typography>
                        ) : (
                            <Typography sx={{ color: 'text.secondary' }}>
                                Nothing Selected Yet
                            </Typography>
                        )}
                    </AccordionSummary>
                    <AccordionDetails>
                        <BillingAddress
                            ref={billingRef}
                            billingAddress={billingAddress}
                            setBillingAddress={setBillingAddress}
                            billingAddressData={billingAddressData}

                        />
                    </AccordionDetails>
                </Accordion>

              

                {/* Shipping */}
                <Accordion
                    expanded={expanded === 'panel2'}
                    onChange={handleChange('panel2')}
                    sx={{ border: shippingError }}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography sx={{ width: '66%', flexShrink: 0 }}>
                            Select Shipping Address
                        </Typography>
                        
                        {/* Print the selected Address */}
                        {shippingAddress !== undefined ? (
                            <Typography sx={{ color: 'text.secondary' }}>
                                {`${shippingAddress.ShipTo_Address1} ${shippingAddress.ShipTo_Address2} ${shippingAddress.ShipTo_City}, ${shippingAddress.ShipTo_State} ${shippingAddress.ShipTo_ZIP}, ${shippingAddress.ShipTo_Country}`}
                            </Typography>
                        ) : (
                            <Typography sx={{ color: 'text.secondary' }}>
                                Nothing Selected Yet
                                
                            </Typography>
                        )}
                    </AccordionSummary>
                    <div className="select-container" style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                            <select ref={stateRef} style={{ marginRight: '20px', width: '100px', height: '30px' }} onChange={handleSelect}>
                                <option value="">All States</option>
                                {uniqueStates.map((value, index) => (
                                <option key={`state[${index}]`} value={value.ShipTo_State}>
                                    {value.state}
                                </option>
                                ))}
                            </select>
                            <select ref={cityRef} style={{ marginRight: '20px', width: '100px', height: '30px' }} onChange={handleSelect}>
                                <option value="">All Cities</option>
                                {uniqueCities.map((value, index) => (
                                <option key={`city${index}`} value={value.ShipTo_City}>
                                    {value.city}
                                </option>
                                ))}
                            </select>
                            <select ref={zipRef} style={{ marginRight: '20px', width: '100px', height: '30px' }} onChange={handleSelect}>
                                <option value="">All ZipCodes</option>
                                {uniqueZips.map((value, index) => (
                                <option key={`zip${index}`} value={value.ShipTo_ZIP}>
                                    {value.zip}
                                </option>
                                ))}
                            </select>
                            </div>
                    <AccordionDetails>
                        <ShippingAddress
                            ref={shippingRef}
                            shippingAddress={shippingAddress}
                            setShippingAddress={setShippingAddress}
                            shippingAddressData={shippingAddressData}
                            setCurrentPage= {setCurrentPage}
                            resetPages={resetPages}
                            setResetPages={setResetPages}
                            checkRadio={checkRadio}
                            setcheckRadio = {setcheckRadio}
                            setResetFormik ={setResetFormik}
                            ResetFormik ={ResetFormik}
                            filterValue = {filterValue}
                            setFilterValue={ setFilterValue}
                           
                            
                        />
                    </AccordionDetails>
                </Accordion>
               
            </Grid>
            
            <Grid container justifyContent="space-between" mt={3}>
                <Grid item>
                    <Button
                        component={Link}
                        to="/placeorder"
                        variant="contained"
                        color="neutral"
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={reviewOrder}>
                        Review Your Order
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default withSnackbar(PickAddress);