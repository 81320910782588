import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import Login from './pages/Auth/Login';
// Import all pages here
import Home from './pages/Home/Home';
import InventoryStatus from './pages/Inventory/InventoryStatus';
import PartNumberInformation from './pages/Inventory/PartNumberInformation';
import NoMatch from './pages/NoMatch';
import OrderConfirm from './pages/Orders/OrderConfirm';
import PickAddress from './pages/Orders/PickAddress';
import PlaceOrderNew from './pages/Orders/PlaceOrderNew';
import SalesOrderStatus from './pages/Orders/SalesOrderStatus';
import ViewSalesOrder from './pages/Orders/ViewSalesOrder';

// TODO: need to ensure routes are protected
export const routes = [
    {
        path: '/',
        component: Login,
        exact: true,
    },
    {
        path: '/home',
        component: Home,
        exact: true,
        protected: true,
    },
    {
        path: '/inventory',
        component: InventoryStatus,
        exact: true,
        protected: true,
    },
    {
        path: '/part',
        component: PartNumberInformation,
        exact: true,
        protected: true,
    },
    {
        path: '/orders',
        component: SalesOrderStatus,
        exact: true,
        protected: true,
    },
    {
        path: '/placeorder',
        component: PlaceOrderNew,
        exact: true,
        protected: true,
    },
    // {
    //     path: '/placeorder',
    //     component: PlaceOrder,
    //     exact: true,
    //     protected: true,
    // },
    {
        path: '/placeorder/address',
        component: PickAddress,
        exact: false,
        protected: true,
    },
    {
        path: '/placeorder/review',
        component: OrderConfirm,
        exact: false,
        protected: true,
    },
    {
        path: '/vieworder',
        component: ViewSalesOrder,
        exact: true,
        protected: true,
    },
    {
        path: '*',
        component: NoMatch,
        exact: true,
    },
    // Routes format, with subroutes
    // You need the exact flag set to help you
    //   {
    //     path: "/",
    //     component: Sandwiches
    //   },
    //   {
    //     path: "/tacos",
    //     component: Tacos,
    //     routes: [
    //       {
    //         path: "/tacos/bus",
    //         component: Bus
    //       },
    //       {
    //         path: "/tacos/cart",
    //         component: Cart
    //       }
    //     ]
    //   }
];

export const RouteWithSubRoutes = (route) => {
    const user = useSelector((state) => state.user);

    // If route should be protected check if the user is logged in, if not redirect to login page
    if (route.protected !== undefined && route.protected === true) {
        // console.log(route.location);
        return (
            <Route
                path={route.path}
                exact={route.exact}
                render={(props) => {
                    return user !== null ? (
                        <route.component {...props} routes={route.routes} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: {
                                    pastLocation: route.location,
                                    message:
                                        'You are unable to view this page. Please login to view that page',
                                },
                            }}
                        />
                    );
                }}
            />
        );
    }

    return (
        <Route
            path={route.path}
            exact={route.exact}
            render={(props) => (
                // pass the sub-routes down to keep nesting
                <route.component {...props} routes={route.routes} />
            )}
        />
    );
};
