import {
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import _debounce from 'lodash/debounce';
import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import * as yup from 'yup';
const PostalCodeRegex = /^[A-Za-z0-9]{3}-[A-Za-z0-9]{3}$|^\d{5}(-\d{4})?$/;
const validationSchema = yup.object({
    Customer_BTName: yup.string('Enter Name').required('Required'),
    Customer_BTAddress1: yup.string('Enter Address1').required('Required'),
    Customer_BTAddress2: yup.string('Enter Address2'),
    Customer_BTAddress3: yup.string('Enter Address3'),
    ShipTo_ZIP: yup.string().matches(PostalCodeRegex, 'Invalid ZIP code'),
    Customer_BTCity: yup.string('Enter City').required('Required'),
    Customer_BTState: yup.string('Enter City').required('Required'),
    Customer_BTCountry: yup.string('Enter Country'),
    Customer_BTPhoneNum: yup.string(),
    Customer_BTFaxNum: yup.string(),
    Customer_BTEmail: yup.string(),
});

const BillingAddress = forwardRef((props, ref) => {
    const { billingAddress, setBillingAddress, billingAddressData } = props;
    const [selectedBilling, setSelectedBilling] = useState(billingAddress);
    const formik = useFormik({
        initialValues: {
            Customer_BTName: selectedBilling
                ? selectedBilling.Customer_BTName
                : '',
            Customer_BTAddress1: selectedBilling
                ? selectedBilling.Customer_BTAddress1
                : '',
            Customer_BTAddress2: selectedBilling
                ? selectedBilling.Customer_BTAddress2
                : '',
            Customer_BTAddress3: selectedBilling
                ? selectedBilling.Customer_BTAddress3
                : '',
            Customer_BTZip: selectedBilling
                ? selectedBilling.Customer_BTZip
                : '',
            Customer_BTCity: selectedBilling
                ? selectedBilling.Customer_BTCity
                : '',
            Customer_BTState: selectedBilling
                ? selectedBilling.Customer_BTState
                : '',
            Customer_BTCountry: selectedBilling
                ? selectedBilling.Customer_BTCountry
                : '',
            Customer_BTPhoneNum: selectedBilling
                ? selectedBilling.Customer_BTPhoneNum
                : '',
            Customer_BTFaxNum: selectedBilling
                ? selectedBilling.Customer_BTFaxNum
                : '',
            Customer_BTEmail: selectedBilling
                ? selectedBilling.Customer_BTEmail
                : '',
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
    });

    // If the props change make sure to update the local state
    useEffect(() => {
        setSelectedBilling(billingAddress);
    }, [billingAddress]);

    // Forces the user to auto select the first address
    useEffect(() => {
        if (billingAddressData) {
            // Force the user to pick the first option
            selectedAddress(0);
        }
    }, [billingAddressData]);

    // When the radio button is click, store in state and tell parent
    const selectedAddress = (value) => {
        setSelectedBilling(billingAddressData[value]);
        dedounceFn(billingAddressData[value]);
    };

    // Debounce FN to tell the parent without spamming updates
    const dedounceFn = useCallback(
        _debounce((billingAddr) => setBillingAddress(billingAddr), 500),
        []
    );

    // Update the state whenever any field is modified
    const updateState = (event) => {
        // console.log(event.target.id);
        // console.log(event.target.value);
        let billing = {
            ...selectedBilling,
            [event.target.id]: event.target.value,
        };

        setSelectedBilling(billing);
        dedounceFn(billing);
    };

    // Reference function for parent class (Pick Address) to call and see if any errors are present
    useImperativeHandle(ref, () => ({
        isValid() {
            // This is returns the errors
            return formik.validateForm();
        },
    }));

    // Render the radio buttons based on
    let renderBillingRadio = <CircularProgress />;
    if (billingAddressData !== undefined) {
        renderBillingRadio = billingAddressData.map((address, index) => (
            <FormControlLabel
                key={`BillingAddr${index}`}
                value={index}
                control={<Radio />}
                label={`${address.Customer_BTAddress1} ${address.Customer_BTAddress2} ${address.Customer_BTCity}, ${address.Customer_BTState} ${address.Customer_BTZip}, ${address.Customer_BTCountry}`}
                onChange={(event) => selectedAddress(event.target.value)}
            />
        ));
    }
    return (
        <Grid container>
            {/* Radio Btn for Selecting their Billing Address */}
            <Grid item xs={4}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Addresses</FormLabel>
                    <RadioGroup defaultValue={0}>
                        {renderBillingRadio}
                    </RadioGroup>
                </FormControl>
            </Grid>

            {/* Form so users can edit the Billing Address details */}
            <Grid item xs={8}>
                <Grid container justifyContent="center">
                    <Grid item>
                        <form>
                            {/* Name */}
                            <Grid item mt={1}>
                                <TextField
                                    label="Name"
                                    variant="outlined"
                                    size="small"
                                    id="Customer_BTName"
                                    required
                                    disabled
                                    value={formik.values.Customer_BTName}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        updateState(e);
                                    }}
                                    error={Boolean(
                                        formik.errors.Customer_BTName
                                    )}
                                    helperText={formik.errors.Customer_BTName}
                                />
                            </Grid>

                            {/* Address1 */}
                            <Grid item mt={1}>
                                <TextField
                                    label="Address"
                                    variant="outlined"
                                    size="small"
                                    id="Customer_BTAddress1"
                                    required
                                    disabled
                                    value={formik.values.Customer_BTAddress1}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        updateState(e);
                                    }}
                                    error={Boolean(
                                        formik.errors.Customer_BTAddress1
                                    )}
                                    helperText={
                                        formik.errors.Customer_BTAddress1
                                    }
                                />
                            </Grid>

                            {/* Address2 */}
                            <Grid item mt={1}>
                                <TextField
                                    label="Address Line 2"
                                    variant="outlined"
                                    size="small"
                                    id="Customer_BTAddress2"
                                    disabled
                                    value={formik.values.Customer_BTAddress2}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        updateState(e);
                                    }}
                                />
                            </Grid>

                            {/* Zip */}
                            <Grid item mt={1}>
                                <TextField
                                    label="Zip"
                                    variant="outlined"
                                    size="small"
                                    id="Customer_BTZip"
                                    required
                                    disabled
                                    value={formik.values.Customer_BTZip}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        updateState(e);
                                    }}
                                    error={Boolean(
                                        formik.errors.Customer_BTZip
                                    )}
                                    helperText={formik.errors.Customer_BTZip}
                                />
                            </Grid>

                            {/* City */}
                            <Grid item mt={1}>
                                <TextField
                                    label="City"
                                    variant="outlined"
                                    size="small"
                                    id="Customer_BTCity"
                                    required
                                    disabled
                                    value={formik.values.Customer_BTCity}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        updateState(e);
                                    }}
                                    error={Boolean(
                                        formik.errors.Customer_BTCity
                                    )}
                                    helperText={formik.errors.Customer_BTCity}
                                />
                            </Grid>

                            {/* State */}
                            <Grid item mt={1}>
                                <TextField
                                    label="State"
                                    variant="outlined"
                                    size="small"
                                    id="Customer_BTState"
                                    required
                                    disabled
                                    value={formik.values.Customer_BTState}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        updateState(e);
                                    }}
                                    error={Boolean(
                                        formik.errors.Customer_BTState
                                    )}
                                    helperText={formik.errors.Customer_BTState}
                                />
                            </Grid>

                            {/* Phone */}
                            <Grid item mt={1}>
                                <TextField
                                    label="Phone"
                                    variant="outlined"
                                    size="small"
                                    id="Customer_BTPhoneNum"
                                    value={formik.values.Customer_BTPhoneNum}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        updateState(e);
                                    }}
                                    disabled
                                />
                            </Grid>

                            {/* Fax */}
                            <Grid item mt={1}>
                                <TextField
                                    label="Fax"
                                    variant="outlined"
                                    size="small"
                                    id="Customer_BTFaxNum"
                                    value={formik.values.Customer_BTFaxNum}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        updateState(e);
                                    }}
                                    disabled
                                />
                            </Grid>

                            {/* Email */}
                            <Grid item mt={1}>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    size="small"
                                    id="Customer_BTEmail"
                                    value={formik.values.Customer_BTEmail}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        updateState(e);
                                    }}
                                    disabled
                                />
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});

export default BillingAddress;
