import { Breadcrumbs, Button, Grid, Link, Paper, Typography } from '@mui/material';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import API from '../../common/axios/api';
import { getQueryParam } from '../../common/utils/util';
import PartImage from './components/PartImage';
import urlencode from 'urlencode';
import store, { set } from 'store';
import update from 'store/plugins/update';
import UpdatePartModal from '../Orders/components/UpdatePartModal';
import { DataGrid } from '@mui/x-data-grid';


const PartNumberInformation = (props) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedPart, setSelectedPart] = useState(null);
    const [data, setData] = useState([]);
    const [editingPart, setEditingPart] = useState();
    // const [part, setPart] = useState();
    const [cart, setCart] = useState([]);
    const location = useLocation();
    const [titleID, setTitleID] = useState();
    let id = getQueryParam('id', location);
    const user = useSelector((state) => state.user);
    const [part, setPart] = useState();
    const [isAddedToCart, setIsAddedToCart] = useState(false);
    let rowID;

    store.addPlugin(update);
    // Query for part data onload
    useEffect(() => {
        // This is to accomodate Part numbers that contain a # in it
        if (location.hash !== '') {
            id += `${location.hash.split('&')[0]}`;
        }
        setTitleID(`${id}`);

        let filter = [];
        // Which BAQ we will access and options
        let partAPI = new API('SIG_API_CustomerInventory');

        if (user !== null) {
            // Need to query by corp codes's attached to the user
            user.corpCodes.forEach((corpCode) => {
                filter.push(`Part_CorpCode_c eq '${corpCode}'`);
            });
            // TODO: Change back
            // user.corpCodes.forEach((corpCode) => {
            //     filter.push(`Part_CorpID_c eq ${corpCode}`);
            // });
            // Attach it to the api obj
            // Part_CorpCode_c eq 1234 or Part_CorpCode_c eq 5412
            if (filter.length > 0) {
                partAPI.setFilter(`(${filter.join(' or ')}) and `);
            }

            // Get the Part by Part id
            // console.log('id', id);
            // console.log('id_urlencoded', urlencode.encode(id));
            partAPI.setFilter(urlencode.encode(`Part_PartNum eq '${urlencode.encode(id)}'`));
            // console.log(partAPI.filter);
            partAPI.setCount();

            // WE ARE GETTING THE PART NUM INFO MAINLY FOR IMG DATA
            partAPI
                .get()
                .then((response) => {
                    // console.log('Part Dtls', response);

                    // Set the information if the response is not empty
                    if (response.data.value.length > 0) {
                        setPart(response.data.value[0]);
                        let savedCart = store.get('cart');
                        // const searchParams = new URLSearchParams(document.location.hash.split('&')[1]);
                        // console.log("searchParams", searchParams);
                        // let rowID = parseInt(searchParams.get('rowId'));
                        // console.log("location.hash in response", location.hash);
                        if (location.hash !== '') {
                            rowID = parseInt(location.hash.split('&')[1].split('=')[1]);
                            // console.log("rowID with hash", rowID);
                        }
                        else {
                            const searchParams = new URLSearchParams(document.location.search);
                            rowID = parseInt(searchParams.get('rowId'))
                            // console.log("rowID with search", rowID);
                        }
                        // console.log("rowID", rowID);
                        if (savedCart !== undefined) {
                            let itemIndex = savedCart.items.findIndex((item) => item.id === rowID);
                            // console.log("SavedCart Item", savedCart.items[itemIndex]);
                            if (itemIndex !== -1) {
                                // console.log(savedCart.items[itemIndex])
                                setIsAddedToCart(true);
                                setSelectedPart(savedCart.items[itemIndex])
                            }
                            else {
                                setSelectedPart(formatData(response.data.value[0]));
                            }
                        }

                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }


    }, [user]);

    const handleOpenModal = () => {
        // console.log("this function called", selectedPart);
        setModalOpen(true);
    };

    if (location.hash !== '') {
        rowID = parseInt(location.hash.split('&')[1].split('=')[1]);
    }
    else {
        const searchParams = new URLSearchParams(document.location.search);
        rowID = parseInt(searchParams.get('rowId'))
    }
    const formatData = (data) => {
        return {
            id: rowID,
            partNum: data.Part_PartNum,
            category: data.Part_WebCategory_c,
            desc: data.Part_PartDescription,
            formattedPrice: numeral(data.Part_UnitPrice).format(
                '$0,0.00'
            ),
            price: data.Part_UnitPrice,
            quantity: data.quantity,
            personalization: data.personalization,
            isPersonalizable: data.Calculated_PartPersonalized,
            personalizationPrompt: data.Part_Personalization_Prompt_c,
            isAddedToCart: false,
            PartRev_RevisionNum: data.PartRev_RevisionNum,
            parentCategory: data.Part_WebCategory_c
        }
    };

    const updateRow = (quantity, personalization) => {
        setIsAddedToCart(true);
        // let data = part;
        // console.log('part Data', data);
        let newData = formatData(part);


        // Update the quantity in data (the source of truth)
        newData.quantity = quantity;
        newData.personalization = personalization;
        newData.isAddedToCart = true;
        // console.log('formattedData', newData);

        setSelectedPart(newData);
        // console.log('selectedPart', selectedPart);

        setModalOpen(false);


        // Add item to cookie storage
        if (store.get('cart') === undefined) {
            store.set('cart', { items: [newData] });
        } else {
            // Local Storage
            let ct = store.get('cart');
            let tempCart = ct.items;
            // Look for a duplicate to determine if we are inserting or updating a new entry
            let dupeNdx = tempCart.findIndex((item) => item.id === newData.id);

            if (dupeNdx !== -1) {
                tempCart[dupeNdx] = newData;
            } else {
                tempCart.push(newData);
            }

            setCart(tempCart)

            store.update('cart', (cart) => {
                cart.items = tempCart;
            });
        }
    };

    const deleteItemFromCart = () => {
        let cart = store.get('cart');
        let data = part;
        if (cart !== undefined && cart.items.length > 0) {
            // First update the source of truth
            // let tempData = data;
            // let updateRow = tempData[editingPart.id];

            // Update the quantity in data (the source of truth)
            data.quantity = "";
            data.personalization = "";
            data.isAddedToCart = false;
            // setData([...tempData]);

            // Then remove it from the cookies
            let items = cart.items;
            let itemNdx = items.findIndex((item) => item.id === data.id);

            let tempCart = cart.items;
            tempCart.splice(itemNdx, 1);

            // Save cart
            store.update('cart', (cart) => {
                cart.items = tempCart;
            });

            setCart(tempCart);

            setSelectedPart(data);
            setEditingPart(undefined);
            setModalOpen(false);
            setIsAddedToCart(false);

        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item md={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        underline="hover"
                        color="inherit"
                        component={RouterLink}
                        to="/"
                    >
                        Home
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        component={RouterLink}
                        to="/inventory"
                    >
                        Inventory
                    </Link>
                    <Typography color="text.primary">{titleID}</Typography>
                </Breadcrumbs>
            </Grid>
            <Button
                variant='contained'
                onClick={() => props.history.goBack()}
                style={{
                    margin: '2% 0 0 2%',
                }}
            >
                Back
            </Button>

            <Grid item md={12}>
                <Typography variant="h4" align="center">
                    {titleID}
                </Typography>
            </Grid>

            {part !== undefined && (
                <>
                    {/* Part Image */}
                    <Grid item md={12}>
                        <Paper>
                            <PartImage part={part} />
                        </Paper>
                    </Grid>

                    {/* Part Information */}
                    <Grid item>
                        <Paper sx={{ padding: '10px' }}>
                            <Typography variant="h4">Part Details</Typography>
                            {/* Headers */}
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Typography variant="body2">
                                        <b>Part Number:</b> <br />
                                        <b>Part Description:</b> <br />
                                        <b>Part Price:</b>
                                        {/* <br /> */}
                                        {/* <b>Calculated_InStockQty:</b> <br />
                                        <b>Calculated_WipQty:</b> <br />
                                        <b>Calculated_Committed:</b> <br /> */}
                                    </Typography>
                                </Grid>
                                {/* Part Data */}
                                <Grid item>
                                    <Typography variant="body2">
                                        {part.Part_PartNum} <br />
                                        {part.Part_PartDescription} <br />
                                        {numeral(part.Part_UnitPrice).format(
                                            '$0,0.00'
                                        )}
                                        {/* <br />
                                        {part.Calculated_InStockQty} <br />
                                        {part.Calculated_WipQty} <br />
                                        {part.Calculated_Committed} <br /> */}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Paper>

                    </Grid>
                </>
            )}

            {isAddedToCart ? <Button
                variant="contained"
                href=''
                style={{
                    width: "16vh",

                    height: "5vh",

                    borderRadius: "10px",

                    fontSize: "12px",

                    color: "#fff",

                    backgroundColor: "#1876D1",

                    margin: "2% 0 0 18%"
                }}
                onClick={handleOpenModal}
            >
                EDIT CART
            </Button> : <Button


                style={{
                    width: "16vh",

                    height: "5vh",

                    borderRadius: "10px",

                    fontSize: "12px",

                    color: "#fff",

                    backgroundColor: "#1876D1",

                    margin: "2% 0 0 18%"
                }}
                onClick={handleOpenModal}
            >
                ADD TO CART
            </Button>}

            {/* {console.log("selectedPart", selectedPart)} */}
            {selectedPart && (
                <UpdatePartModal
                    open={modalOpen}
                    handleClose={() => setModalOpen(false)}
                    row={selectedPart}
                    updateRow={(quantity, personalization) =>
                        updateRow(quantity, personalization)
                    }
                    deleteRow={deleteItemFromCart}
                />
            )}


        </Grid>
    );
};

export default PartNumberInformation;
