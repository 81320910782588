import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import store from 'store';
import update from 'store/plugins/update';
import CartAddress from './components/CartAddress';
import CartTable from './components/CartTable';
import OrderInformation from './components/OrderInformation';
import UpdatePartModal from './components/UpdatePartModal';

const OrderConfirm = (props) => {
    let history = useHistory();
    const [cart, setCart] = useState();
    const [shippingAddr, setShippingAddr] = useState();
    const [billingAddr, setBillingAddr] = useState();
    const [editingPart, setEditingPart] = useState();
    const [openEditModal, setOpenEditModal] = useState(false);

    // Add the update plugin to the localstorage helper
    store.addPlugin(update);
    const user = useSelector((state) => state.user);

    // Try to get load cookie info
    useEffect(() => {
        // Check to see if the cart, billing and shipping is defined
        let bill = store.get('bill');
        let ship = store.get('ship');
        let cart = store.get('cart');
        if (cart === undefined || bill === undefined || ship === undefined) {
            history.push('/home');
        } else {
            // Get cookie info

            // Need to flatten item array and only display items that have a quantity attribute
            let items = [];
            for (var i = 0; i < cart.items.length; i++) {
                // This looks through each corp kits and if a quantity attr is present add it to the arr
                items.push(cart.items[i]);
            }
            setCart(items);

            // Set the Addresses
            setShippingAddr(ship.shipping);
            setBillingAddr(bill.billing);
        }
    }, [user]);

    const orderComplete = (orderNum) => {
        history.push('/home', [
            {
                message: `Order #${orderNum} has been placed!`,
            },
        ]);
    };

    const onClick = (row) => {
        // e.stopPropagation(); // don't select this row after clicking

        // let rowData = params.row;
        setEditingPart(row);
        setOpenEditModal(true);
    };

    const updateItem = (quantity, personalization) => {
        let row = editingPart;

        row.quantity = quantity;
        row.personalization = personalization;
        row.isAddedToCart = true;

        setEditingPart(undefined);
        setOpenEditModal(false);

        let ct = store.get('cart');
        let tempCart = ct.items;
        // Look for a duplicate to determine if we are inserting or updating a new entry
        let dupeNdx = tempCart.findIndex((item) => item.id === row.id);

        if (dupeNdx !== -1) {
            tempCart[dupeNdx] = row;
        } else {
            tempCart.push(row);
        }

        store.update('cart', (cart) => {
            cart.items = tempCart;
        });
    }

    const deleteItemFromCart = () => {
        let cart = store.get('cart');

        if (cart !== undefined && cart.items.length > 0) {
            let items = cart.items;
            let itemNdx = items.findIndex((item) => item.id === editingPart.id);

            let tempCart = cart.items;
            tempCart.splice(itemNdx, 1);

            // Save cart
            store.update('cart', (cart) => {
                cart.items = tempCart;
            });

            // Update local cart for view
            cart = store.get('cart')

            if (cart === undefined || cart.items.length == 0) {
                history.push('/home');
            } else {
                items = [];
                for (var i = 0; i < cart.items.length; i++) {
                    // This looks through each corp kits and if a quantity attr is present add it to the arr
                    items.push(cart.items[i]);
                }
                setCart(items);
            }

            setEditingPart(undefined);
            setOpenEditModal(false);
        }
    };

    return (
        <Grid container spacing={2} mt={3}>
            <Grid item xs={6} mb={6}>
                <Grid container direction="column">
                    {/* Show what is in the cart */}
                    <Grid item>
                        <CartTable cart={cart} isEditable editRow={(row) => onClick(row)}/>
                    </Grid>

                    {/* The Final Form to submit an order */}
                    <Grid item>
                        <OrderInformation orderComplete={orderComplete} />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
                <Grid container direction="column">
                    <Grid item>
                        <CartAddress
                            shippingAddr={shippingAddr}
                            billingAddr={billingAddr}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <UpdatePartModal
                open={openEditModal}
                handleClose={() => setOpenEditModal(false)}
                row={editingPart}
                updateRow={(quantity, personalization) =>
                    updateItem(quantity, personalization)
                }
                deleteRow={deleteItemFromCart}
            />
        </Grid>
    );
};

export default OrderConfirm;
