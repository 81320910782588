import {
  // Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  // FormGroup,
  MenuItem,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Pagination,
} from "@mui/material";
import { useFormik } from "formik";
import debounce from 'lodash/debounce';
// import _debounce from "lodash/debounce";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import * as yup from "yup";
import "../../../image.css";
const PostalCodeRegex = /^[A-Za-z0-9]{3}-[A-Za-z0-9]{3}$|^\d{5}(-\d{4})?$/;
const validationSchema = yup.object({
  ShipTo_Name: yup.string("Enter Name").required("Required"),
  ShipTo_Address1: yup.string("Enter Address1").required("Required"),
  ShipTo_Address2: yup.string("Enter Address2"),
  ShipTo_Address3: yup.string("Enter Address3"),
  ShipTo_ZIP: yup.string().matches(PostalCodeRegex, "Invalid ZIP code").required("Required"),
  ShipTo_City: yup.string("Enter City").required("Required"),
  ShipTo_State: yup.string("Enter City").required("Required"),
  ShipTo_PhoneNum: yup.string(),
  ShipTo_FaxNum: yup.string(),
  ShipTo_Email: yup.string(),
  ShipTo_Attention: yup.string(),
  ShipTo_CountryNum: yup.number().min(1).required("Required"),
  SaveAddress: yup.boolean("Save Address"),
});

const ShippingAddress = forwardRef((props, ref) => {
  let {
    shippingAddress,
    setShippingAddress,
    shippingAddressData,
    resetPages,
    setResetPages,
    checkRadio,
    setcheckRadio,
  } = props;
  const [selectedShipping, setSelectedShipping] = useState(null);

  let [currentPage, setCurrentPage] = useState(1);
  const [selectedValue, setSelectedValue] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [shipCountries, setShipCountries] = useState([]);
  const itemsPerPage = 10;
  const initialValues = {
    ShipTo_Name: selectedShipping ? selectedShipping.ShipTo_Name : "",
    ShipTo_Address1: selectedShipping ? selectedShipping.ShipTo_Address1 : "",
    ShipTo_Address2: selectedShipping ? selectedShipping.ShipTo_Address2 : "",
    ShipTo_Address3: selectedShipping ? selectedShipping.ShipTo_Address3 : "",
    ShipTo_ZIP: selectedShipping ? selectedShipping.ShipTo_ZIP : "",
    ShipTo_City: selectedShipping ? selectedShipping.ShipTo_City : "",
    ShipTo_State: selectedShipping ? selectedShipping.ShipTo_State : "",
    ShipTo_PhoneNum: selectedShipping ? selectedShipping.ShipTo_PhoneNum : "",
    ShipTo_FaxNum: selectedShipping ? selectedShipping.ShipTo_FaxNum : "",
    ShipTo_Email: selectedShipping ? selectedShipping.ShipTo_Email : "",
    ShipTo_Attention: selectedShipping ? selectedShipping.ShipTo_Attention : "",
    ShipTo_CountryNum: selectedShipping
      ? selectedShipping.ShipTo_CountryNum
      : "",
    SaveAddress: selectedShipping ? selectedShipping.SaveAddress : false,
    // other fields
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
  });

  // If the props change make sure to update the local state
  useEffect(() => {
    setSelectedShipping(shippingAddress);
  }, [shippingAddress]);

  // console.log(selectedShipping, "selectedShipping");

    const selectedAddress = (event) => {
      let value = event.target.value;
      const selectedShippingAddress = shippingAddressData[value];
  
      if (selectedShippingAddress.ShipTo_CountryNum === 0) {
        selectedShippingAddress.ShipTo_CountryNum = 1;
        // selectedShippingAddress.ShipTo_Country = "United States";
      }
      else if(selectedShippingAddress.ShipTo_Country!==null) {
        selectedShippingAddress.ShipTo_CountryNum = shipCountries.find((item => item.Country_Description === selectedShippingAddress.ShipTo_Country)).Country_CountryNum;
      }
      let selectedShipping = {
        ...selectedShippingAddress,
        OTS: true,
      };
      // console.log(selectedShippingAddress, "selectedShippingAddress");
      setSelectedShipping(selectedShipping);
      setShippingAddress(selectedShipping);
      dedounceFn(selectedShipping);
    };

  // Debounce FN to tell the parent without spamming updates
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dedounceFn = useCallback(
    debounce((shippingAddr) => setShippingAddress(shippingAddr), 500),
    [setShippingAddress]
  );

  useEffect(() => {
    // Simulate data fetch with a timeout
    // Set Countries code
    let cloudFrontUrl = "https://ddm90ybgne3qd.cloudfront.net/Countries.json";
    fetch(cloudFrontUrl)
      .then((response) => {
        if (response.ok) {
          return response.json(); // or response.json() if expecting JSON data
        }
        throw new Error("Error retrieving S3 object via CloudFront.");
      })
      .then((data) => {
        setShipCountries(data.value); // Do something with the retrieved object
      })
      .catch((error) => {
        console.error(error);
      });
    setTimeout(() => {
      setIsLoading(false); // <- Update loading state
    }, 4000); // <- Delay of 3 seconds
  }, []);

  function setShipCountry(value) {
    formik.setFieldValue("ShipTo_CountryNum", value);
    selectedShipping.ShipTo_CountryNum = value;
    selectedShipping.ShipTo_Country = shipCountries.find((item => item.Country_CountryNum === value)).Country_Description;
    // console.log(selectedShipping, "selectedShipping after country change")
    setShippingAddress(selectedShipping);
    // let country = shipCountries.find(item => item.Country_CountryNum === value);
    // console.log("country", country.Country_Description);
    // formik.setFieldValue("ShipTo_Country", country.Country_Description);
  }

  // Update the state whenever any field is modified
  const updateState = (event) => {
    let shipping = {
      ...selectedShipping,
      [event.target.id]: event.target.value,
      OTS: true,
    };

    setcheckRadio(true);

    setSelectedShipping(shipping);
    // console.log(selectedShipping, "selectedShipping");

    dedounceFn(shipping);
  };

  // Reference function for parent class (Pick Address) to call and see if any errors are present
  useImperativeHandle(ref, () => ({
    isValid() {
      // This is returns the errors
      return formik.validateForm();
    },
  }));

  const handlePageClick = (event, page) => {
    setResetPages(false);
    setCurrentPage(page);
  };

  const pageNumbers = [];
  if (shippingAddressData !== undefined) {
    const totalPages = Math.ceil(shippingAddressData.length / itemsPerPage);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  }

  // console.log(pageNumbers.length, "pageNumbers.length");
  // console.log("test1", checkRadio);

  let renderShippingRadio = <CircularProgress />;
  if (!isLoading && shippingAddressData && shippingAddressData.length) {
    if (resetPages === true) {
      currentPage = 1;
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = shippingAddressData.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    renderShippingRadio = currentItems.map((address, index) => {
      const value = index + indexOfFirstItem;
      // console.log(value);
      return (
        <FormControlLabel
          key={`ShipAddr${value}`}
          value={value}
          onChange={(event) => {
            setcheckRadio(true);
            setSelectedValue(value);
            selectedAddress(event);
          }}
          checked={checkRadio && selectedValue === value}
          control={<Radio />}
          label={`${address.ShipTo_Address1} ${address.ShipTo_Address2} ${address.ShipTo_City}, ${address.ShipTo_State} ${address.ShipTo_ZIP}, ${address.ShipTo_Country}`}
        />
      );
    });

    // console.log(selectedValue, "selectedValue");
  }
  // console.log("test2", checkRadio);  
  return (
    <Grid container>
      {/* Radio Btn for Selecting their Shipping Address */}
      <Grid item xs={4}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Addresses</FormLabel>
          <RadioGroup aria-label="shipaddr">
            {isLoading && <div>{renderShippingRadio}</div>}
            {!isLoading &&
              shippingAddressData &&
              shippingAddressData.length > 0 && (
                <div>{renderShippingRadio}</div>
              )}
            {!isLoading &&
              (!shippingAddressData || shippingAddressData.length === 0) && (
                <p>No shipping addresses found.</p>
              )}
          </RadioGroup>
          <div style={{ marginTop: "10px", textAlign: "center" }}>
            <Pagination
              count={pageNumbers.length}
              size="large"
              page={currentPage}
              onChange={handlePageClick}
            />
          </div>
        </FormControl>
      </Grid>

      {/* Form so users can edit the Shipping Address details */}
      <Grid item xs={8}>
        <Grid container justifyContent="center">
          <Grid item>
            <form id="ship">
              {/* Name */}
              <Grid item mt={1}>
                <TextField
                  label="Name"
                  variant="outlined"
                  size="small"
                  id="ShipTo_Name"
                  required
                  value={formik.values.ShipTo_Name}
                  // test={console.log(formik.values.ShipTo_Name)}
                  onChange={(e) => {
                    formik.handleChange(e);
                    updateState(e);
                  }}
                  error={Boolean(formik.errors.ShipTo_Name)}
                  helperText={formik.errors.ShipTo_Name}
                />
              </Grid>

              {/* Address1 */}
              <Grid item mt={1}>
                <TextField
                  label="Address"
                  variant="outlined"
                  size="small"
                  id="ShipTo_Address1"
                  required
                  value={formik.values.ShipTo_Address1}
                  onChange={(e) => {
                    formik.handleChange(e);
                    updateState(e);
                  }}
                  error={Boolean(formik.errors.ShipTo_Address1)}
                  helperText={formik.errors.ShipTo_Address1}
                />
              </Grid>

              {/* Address2 */}
              <Grid item mt={1}>
                <TextField
                  label="Address Line 2"
                  variant="outlined"
                  size="small"
                  id="ShipTo_Address2"
                  value={formik.values.ShipTo_Address2}
                  onChange={(e) => {
                    formik.handleChange(e);
                    updateState(e);
                  }}
                />
              </Grid>

              {/* City */}
              <Grid item mt={1}>
                <TextField
                  label="City"
                  variant="outlined"
                  size="small"
                  id="ShipTo_City"
                  required
                  value={formik.values.ShipTo_City}
                  onChange={(e) => {
                    formik.handleChange(e);
                    updateState(e);
                  }}
                  error={Boolean(formik.errors.ShipTo_City)}
                  helperText={formik.errors.ShipTo_City}
                />
              </Grid>

              {/* State */}
              <Grid item mt={1}>
                <TextField
                  label="State"
                  variant="outlined"
                  size="small"
                  id="ShipTo_State"
                  required
                  value={formik.values.ShipTo_State}
                  onChange={(e) => {
                    formik.handleChange(e);
                    updateState(e);
                  }}
                  error={Boolean(formik.errors.ShipTo_State)}
                  helperText={formik.errors.ShipTo_State}
                />
              </Grid>

              {/* Zip */}
              <Grid item mt={1}>
                <TextField
                  label="Zip"
                  variant="outlined"
                  size="small"
                  id="ShipTo_ZIP"
                  required
                  value={formik.values.ShipTo_ZIP}
                  onChange={(e) => {
                    formik.handleChange(e);
                    updateState(e);
                  }}
                  error={Boolean(formik.errors.ShipTo_ZIP)}
                  helperText={formik.errors.ShipTo_ZIP}
                />
              </Grid>

              {/* Country */}
              {/* <Grid item mt={1}>
                                <TextField
                                    label="Country"
                                    variant="outlined"
                                    size="small"
                                    id="ShipTo_ZIP"
                                    required
                                    value={formik.values.ShipTo_CountryNum}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        updateState(e);
                                    }}
                                    error={Boolean(formik.errors.ShipTo_CountryNum)}
                                    helperText={formik.errors.ShipTo_CountryNum}
                                />
                            </Grid> */}

              <Grid item mt={1}>
                <TextField
                  sx={{ width: "195px" }}
                  label="Country"
                  variant="outlined"
                  size="small"
                  id="ShipTo_Country"
                  select
                  required
                  value={formik.values.ShipTo_CountryNum}
                  // onChange={(e) => {
                  //   formik.handleChange(e);
                  //   updateState(e);
                  // }}
                  // error={Boolean(formik.errors.ShipTo_CountryNum)}
                  // helperText={formik.errors.ShipTo_CountryNum}
                  onChange={(event) => {
                    setShipCountry(event.target.value);
                    formik.handleChange(event);
                    updateState(event);
                  }}
                  error={
                    formik.touched.ShipTo_CountryNum &&
                    Boolean(formik.errors.ShipTo_CountryNum)
                  }
                  helperText={
                    formik.touched.ShipTo_CountryNum &&
                    formik.errors.ShipTo_CountryNum
                  }
                >
                  {shipCountries.map((country) => (
                    <MenuItem
                      key={country.Country_CountryNum}
                      value={country.Country_CountryNum}
                    >
                      {country.Country_Description}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Phone */}
              <Grid item mt={1}>
                <TextField
                  label="Phone"
                  variant="outlined"
                  size="small"
                  id="ShipTo_PhoneNum"
                  value={formik.values.ShipTo_PhoneNum}
                  onChange={(e) => {
                    // formik.handleChange(e);
                    updateState(e);
                  }}
                />
              </Grid>

              {/* Fax */}
              <Grid item mt={1}>
                <TextField
                  label="Fax"
                  variant="outlined"
                  size="small"
                  id="ShipTo_FaxNum"
                  value={formik.values.ShipTo_FaxNum}
                  onChange={(e) => {
                    // formik.handleChange(e);
                    updateState(e);
                  }}
                />
              </Grid>

              {/* Email */}
              <Grid item mt={1}>
                <TextField
                  label="Email"
                  variant="outlined"
                  size="small"
                  id="ShipTo_Email"
                  value={formik.values.ShipTo_Email}
                  onChange={(e) => {
                    // formik.handleChange(e);
                    updateState(e);
                  }}
                />
              </Grid>

              {/* Attention */}
              <Grid item mt={1} style={{ marginBottom: "50px" }}>
                <TextField
                  label="Attention"
                  variant="outlined"
                  size="small"
                  id="ShipTo_Attention"
                  value={formik.values.ShipTo_Attention}
                  onChange={(e) => {
                    // formik.handleChange(e);
                    updateState(e);
                  }}
                />
              </Grid>

              {/* Save Address */}
              {/* <Grid item mt={1}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="SaveAddress"
                                                checked={
                                                    formik.values.SaveAddress
                                                }
                                                test ={console.log(formik.values.SaveAddress,"SaveAddress")}
                                                onChange={(e) => {
                                                    let event = {
                                                        target: {
                                                            value: e.target
                                                                .checked,
                                                            id: 'SaveAddress',
                                                        },
                                                    };
                                                    formik.handleChange(event);
                                                    updateState(event);
                                                }}
                                            />
                                        }
                                        label="Save Address"
                                    />
                                </FormGroup>
                            </Grid> */}
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default ShippingAddress;