/* The
"UPS",http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums= & [Tracking Number],
"FedEx",http://www.fedex.com/Tracking?action=track&tracknumbers= & [Tracking Number],
"DHL",http://track.dhl-usa.com/TrackByNbr.asp?ShipmentNumber= & [Tracking Number],
"USPS", https://tools.usps.com/go/TrackConfirmAction?tLabels= & [Tracking Number],
"XPO", https://app.ltl.xpo.com/appjs/tracking/#/trackingdetails/ & [Tracking Number],
*/
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';

const ShippingStatus = ({ info }) => {
    const [typeLink, setTypeLink] = useState(null);
    const [tracking, setTracking] = useState(null);

    useEffect(() => {
        if (info !== undefined) {
            const shipType = info.shipDesc;
            const trackingInfo = info.shipTrackingNo;

            setTracking(trackingInfo);

            // UPS
            if (shipType.includes('UPS')) {
                setTypeLink(
                    `https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingInfo}`
                );
            } else if (shipType.includes('FedEx')) {
                setTypeLink(
                    `https://www.fedex.com/Tracking?action=track&tracknumbers=${trackingInfo}`
                );
            } else if (shipType.includes('DHL')) {
                setTypeLink(
                    `https://track.dhl-usa.com/TrackByNbr.asp?ShipmentNumber=${trackingInfo}`
                );
            } else if (shipType.includes('USPS')) {
                setTypeLink(
                    `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingInfo}`
                );
            } else if (shipType.includes('XPO')) {
                setTypeLink(
                    `https://app.ltl.xpo.com/appjs/tracking/#/trackingdetails/${trackingInfo}`
                );
            } else {
                // Make it a google search
                if (trackingInfo !== null)
                    setTypeLink(
                        `https://www.google.com/search?q=${shipType} tracking ${trackingInfo}`
                    );
            }
        }
    }, [info]);

    if (typeLink !== null && tracking !== null) {
        return (
            <Button
                variant="outlined"
                href={`${typeLink}`}
                target="_blank"
                rel="noopener"
            >
                View Shipping Status
            </Button>
        );
    } else {
        return (
            <Button variant="text">No Tracking Information at this time</Button>
        );
    }
};

export default ShippingStatus;
