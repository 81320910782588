import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Badge,
    Menu,
    Divider
} from '@mui/material';
import { darken, lighten } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import store, { set } from 'store';
import update from 'store/plugins/update';
import API from '../../common/axios/api';
import { cache } from '../../common/utils/cache';
import { withSnackbar } from '../../common/utils/SnackBarHOC';
import UpdatePartModal from './components/UpdatePartModal';
import { styled } from '@mui/material/styles';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CartTable from './components/CartTable';
import '../../image.css';
import axios from 'axios'

const StyledButton = styled(Button)`
  top: 5px
`;

// const images = ['./MOLSON BRANDS /BLUE MOON EQUITY.png', './MOLSON BRANDS /BLUE MOON EQUITY.png', './MOLSON BRANDS /BLUE MOON EQUITY.png'];

const PlaceOrderNew = (props) => {
    const user = useSelector((state) => state.user);
    const [categoryname, setCategoryName] = useState('');
    const [categorySearchState, setCategorySearchState] = useState('');
    const [tempCatagories, setTempCatagories] = useState([]);
    const [partSearchState, setPartSearchState] = useState('');
    const [dataSize, setDataSize] = useState(0);
    const partnumber = useSelector((state) => state.partnumber);
    const [data, setData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [loading, setLoading] = useState(true);
    let history = useHistory();
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categories, setCatagories] = useState([]);
    const [editingPart, setEditingPart] = useState();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [cart, setCart] = useState([]);
    const [menuAnchor, setMenuAnchor] = React.useState(null);
    const open = Boolean(menuAnchor);
    // const AWS = require('aws-sdk');
    // const rekognition = new AWS.Rekognition();
    // const [imageUrl, setImageUrl] = useState('');

    // const [apiResponse, setApiResponse] = useState("");

    // Add the update plugin to the localstorage helper
    store.addPlugin(update);

    const getBackgroundColor = (color, mode) =>
        mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const getHoverBackgroundColor = (color, mode) =>
        mode !== 'dark' ? darken(color, 0.01) : lighten(color, 1);

    // Which BAQ we will access and options
    let api = new API('SIG_API_CustomerInventory');
    api.setCount();
    api.setOrderby('Part_WebCategory_c');

    useEffect(() => {


        // Get the part/kits arrays and flatten them
        let filter = [];
        if (user !== null) {
            // console.log("hello",user.corpCodes)
            // Need to query by corp id's attached to the user
            user.corpCodes.forEach((corpCode) => {
                filter.push(`Part_CorpCode_c eq '${corpCode}'`);
                // console.log("hello",'${corpCode}')
            });

            if (filter.length > 0) {
                api.setFilter(filter.join(' or '));
            }

            if (store.get('cart') === undefined) {
                store.set('cart', { items: [] });
            }

            cache(api)
                .then((response) => {
                    // console.log('From cache', response);
                    setLoading(false);

                    let countData = Object.values(response.data)[1];
                    //console.log('countData', countData);

                    let apiData = response.data.value;

                    //console.log("API DATA")
                    //console.log(apiData)

                    // formatData(apiData);
                    formatData(apiData);
                    setDataSize(data.length)

                    // I want to list all categories a from the data
                    let categorySet = new Set();
                    apiData.forEach((elem) => {
                        if (elem.Part_WebCategory_c.includes("~")) {
                            let splitCategory = elem.Part_WebCategory_c.split("~");


                            splitCategory.forEach((category) => {
                                categorySet.add(category.trim())
                            })
                        } else {
                            categorySet.add(elem.Part_WebCategory_c);
                        }
                    });
                    // If there is a empty category we will call that unspecified
                    if (categorySet.has('')) {
                        categorySet.delete('');
                        categorySet.add('Unspecified');
                    }
                    setCatagories(Array.from(categorySet).sort((a, b) => (a > b) ? 1 : -1));
                    setTempCatagories(Array.from(categorySet).sort((a, b) => (a > b) ? 1 : -1));
                    // console.log("hello")
                    // console.log(categories);
                    // console.log(categorySet)
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });

        }

    }, [user]);

    // Change the data based on the category selection



    // const s3 = new AWS.S3({
    //     accessKeyId: access_key,
    //     secretAccessKey: secretAccessKey,
    //     region: region_s3
    //   });    
    //      async   function getPresignedUrl( presignedUrl, alternative_url) 
    //        {
    //         return await fetch(presignedUrl)
    //     .then(response => {
    //       if (response.status === 200) {
    //         return presignedUrl;
    //       } else {
    //        return alternative_url
    //       }
    //     })
    //     .catch(error => {
    //       console.error(error);
    //       return alternative_url; // replace with your alternate URL
    //     });
    // }

    const handleCategorySearch = (event) => {
        let search = event.target.value;
        // search = search.toUpperCase();
        setCategoryName(search);
        // setTempCatagories(categories);
        // console.log("search", search);
        // If not empty show category parts
        if (search !== '') {
            const filteredCategories = categories.filter((item) => item.toLowerCase().includes(search.toLowerCase()));

            // If there are no matching categories, return empty
            if (filteredCategories.length === 0) {
                setTempCatagories([]);
            } else {
                setTempCatagories(filteredCategories);
            }
            // setCatagories(Array.from()));
            // console.log("categories length: ", tempCatagories.length);
        }
        else {
            setTempCatagories(categories);
        }
    }


    const handlePartSearch = (event) => {
        let search = event.target.value;

        // console.log(search);
        // captilasing the search
        search = search.toUpperCase();
        setPartSearchState(search);

        let tempArr;
        // If not empty show category parts
        if (partSearchState !== '') {
            // Only find parts that contain the selected category
            tempArr = data.filter((item) => item.partNum.includes(search));
            tempArr = tempArr.concat(data.filter((item) => item.desc.toUpperCase().includes(search)));
            // console.log("tempArr", tempArr);
            setDisplayData(tempArr);
            setDataSize(tempArr.length);
        }
        else {
            // Show all parts
            setDisplayData(data);
            setDataSize(data.length);
        }
    }

    const handleCategoryChange = (event) => {
        let category = event.target.getAttribute('value');
        // console.log("hello")
        // console.log("Selected Category is: ", category);
        setSelectedCategory(category);

        // If not empty show category parts
        if (category !== '' && category !== 'Unspecified') {
            // Only find parts that contain the selected category
            let tempArr = data.filter((item) => item.category === category);
            setDisplayData(tempArr);
            setDataSize(tempArr.length);
        } else if (category === 'Unspecified') {
            // Only find parts that contain the selected category
            let tempArr = data.filter((item) => item.category === '');
            setDisplayData(tempArr);
            setDataSize(tempArr.length);
        } else {
            // Show all parts
            setDisplayData(data);
            setDataSize(data.length);
        }

    };


    // will most likely need to format whenever I get custom inventories sent to me
    const formatData = (data) => {
        if (data.length > 0) {
            data.sort((a, b) => (a.Part_PartDescription > b.Part_PartDescription) ? 1 : -1);

            let index = 0;
            let formatedData = [];
            data.forEach((value) => {
                if (value.Part_WebCategory_c.includes("~")) {
                    let splitCategory = value.Part_WebCategory_c.split("~");

                    splitCategory.forEach((category) => {
                        formatedData.push({
                            id: index,
                            partNum: value.Part_PartNum,
                            category: category.trim(),
                            desc: value.Part_PartDescription,
                            formattedPrice: numeral(value.Part_UnitPrice).format(
                                '$0,0.00'
                            ),
                            price: value.Part_UnitPrice,
                            quantity: '',
                            personalization: '',
                            isPersonalizable: value.Calculated_PartPersonalized,
                            personalizationPrompt: value.Part_Personalization_Prompt_c,
                            isAddedToCart: false,
                            PartRev_RevisionNum: value.PartRev_RevisionNum,
                            parentCategory: value.Part_WebCategory_c
                        })
                        index = index + 1;
                    });
                } else {
                    formatedData.push({
                        id: index,
                        partNum: value.Part_PartNum,
                        category: value.Part_WebCategory_c,
                        desc: value.Part_PartDescription,
                        formattedPrice: numeral(value.Part_UnitPrice).format(
                            '$0,0.00'
                        ),
                        price: value.Part_UnitPrice,
                        quantity: '',
                        personalization: '',
                        isPersonalizable: value.Calculated_PartPersonalized,
                        personalizationPrompt: value.Part_Personalization_Prompt_c,
                        isAddedToCart: false,
                        PartRev_RevisionNum: value.PartRev_RevisionNum,
                        parentCategory: value.Part_WebCategory_c
                    });
                    index = index + 1;
                }
            });

            // get from localstorage
            // I need to check the cookie to see if we should swap a value with it
            let cartItemsFromStorage = store.get('cart');
            // console.log(cartItemsFromStorage);

            if (
                cartItemsFromStorage !== undefined &&
                cartItemsFromStorage.items.length > 0
            ) {
                let items = [];
                for (let i = 0; i < cartItemsFromStorage.items.length; i++) {
                    let cartItem = cartItemsFromStorage.items[i];

                    let ndx = formatedData.findIndex(
                        (item) => item.partNum === cartItem.partNum && item.category === cartItem.category
                    );
                    formatedData[ndx] = cartItem;
                    items.push(cartItem)
                }
                setCart(items)
            }

            // console.log('Formatdata', formatedData);
            setData([...formatedData]);
            setDisplayData([...formatedData]);
        }
    };

    // When A user clicks save it will update the data
    const updateRow = (quantity, personalization) => {
        let tempData = data;
        let row = editingPart;
        let updateRow = tempData[row.id];

        // Update the quantity in data (the source of truth)
        updateRow.quantity = quantity;
        updateRow.personalization = personalization;
        updateRow.isAddedToCart = true;
        setData([...tempData]);
        setEditingPart(undefined);
        setOpenEditModal(false);

        // Add item to cookie storage
        if (store.get('cart') === undefined) {
            store.set('cart', { items: [tempData[row.id]] });
        } else {
            // Local Storage
            let ct = store.get('cart');
            let tempCart = ct.items;
            // Look for a duplicate to determine if we are inserting or updating a new entry
            let dupeNdx = tempCart.findIndex((item) => item.id === row.id);

            if (dupeNdx !== -1) {
                tempCart[dupeNdx] = updateRow;
            } else {
                tempCart.push(updateRow);
            }

            setCart(tempCart)

            store.update('cart', (cart) => {
                cart.items = tempCart;
            });
        }
    };

    const deleteItemFromCart = () => {
        let cart = store.get('cart');

        if (cart !== undefined && cart.items.length > 0) {
            // First update the source of truth
            let tempData = data;
            let updateRow = tempData[editingPart.id];

            // Update the quantity in data (the source of truth)
            updateRow.quantity = "";
            updateRow.personalization = "";
            updateRow.isAddedToCart = false;
            setData([...tempData]);

            // Then remove it from the cookies
            let items = cart.items;
            let itemNdx = items.findIndex((item) => item.id === editingPart.id);

            let tempCart = cart.items;
            tempCart.splice(itemNdx, 1);

            // Save cart
            store.update('cart', (cart) => {
                cart.items = tempCart;
            });

            setCart(tempCart);

            setEditingPart(undefined);
            setOpenEditModal(false);
        }
    };

    const getTotalItems = () => {
        let cartItemsFromStorage = store.get('cart');

        if (
            cartItemsFromStorage !== undefined &&
            cartItemsFromStorage.items.length > 0
        ) {
            return cartItemsFromStorage.items.reduce((acc, item) => acc + item.quantity, 0);
        } else {
            return 0;
        }
    }

    // Save user input into cookie and route to next order page
    const continueToAddress = () => {
        // Validate that the user actually has an item with a quantity > 0

        let cart = store.get('cart');

        if (cart !== undefined && cart.items.length > 0) {
            // Move user to next page
            history.push('/placeorder/address');
        } else {
            // Let the user know they need to fill something out
            props.snackbarShowMessage(
                'You need to add an item to your cart before continuing!',
                'error',
                5000
            );
        }
    };

    // We will have to format data we get from Epicore to fit this format
    const columns = [
        { field: 'id', headerName: 'Table ID' },
        { field: 'desc', headerName: 'Description', minWidth: 150, flex: 1 },
        {
            field: 'category',
            headerName: 'Category',
            minWidth: 300,
        },
        {
            field: 'partNum',
            headerName: 'Part Number',
            width: 150,
            renderCell: (cellValue) => (
                <Link
                    underline="hover"
                    component={RouterLink}
                    to={`/part?id=${cellValue.value}&rowId=${cellValue.row.id}`}
                    target="_self"
                >
                    {cellValue.value}
                </Link>
            ),
        },
        {
            field: 'formattedPrice',
            headerName: 'Price',
            minWidth: 150,
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            minWidth: 150,
        },
        {
            field: 'addPart',
            headerName: 'Add Part',
            width: 150,
            type: 'actions',
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking

                    let rowData = params.row;
                    setEditingPart(rowData);
                    setOpenEditModal(true);
                };

                if (params.row.isAddedToCart) {
                    return (
                        <Button onClick={onClick} variant="contained">
                            Edit Part
                        </Button>
                    );
                }
                return <Button onClick={onClick}>Add Part</Button>;
            },
        },
    ];

    const handleClick = (event) => {
        setMenuAnchor(event.currentTarget);
    };
    const handleClose = () => {
        setMenuAnchor(null);
    };

    const onClick = (row) => {
        setEditingPart(row);
        setOpenEditModal(true);
    };

    //     <Grid item xs={3} >

    //     <FormControl fullWidth>

    //         <InputLabel id="categorySelect-label">
    //             Category
    //         </InputLabel>

    //         <Select
    //             labelId="categorySelect-label"
    //             id="categorySelect"
    //             value={selectedCategory}
    //             label="Category"
    //             onChange={handleCategoryChange}
    //         >

    //             <MenuItem value="" >
    //                 <em>None</em>
    //             </MenuItem>


    //             {categories.map((item, index) => {
    //             const corpCode_name =user.corpCodes[0];
    //             const  image_name=item+'_'+corpCode_name+'.png';
    //            //console.log(image_name)
    //             const distributionDomain = process.env.REACT_APP_CLOUD_FRONT;
    //             // 'ddm90ybgne3qd.cloudfront.net';
    //             const formattedUrl = `https://${distributionDomain}/${image_name}`;
    //             const  presignedUrl= formattedUrl.replace(/ /g, '%20');

    //                 return (
    //                     // <div style={{ position: 'absolute' }}>
    //                     <MenuItem key={index} value={item} >

    //                  <img src={presignedUrl} alt="no image" className="enlarge-image" style={{ height: '50px', width: '200px' }} />

    //                     {/* <img src={presignedUrl} alt= "no image" className="enlarge-image" /> */}
    //                          <span style={{margin: '-2%'}}>{item}</span>

    //                  </MenuItem>

    //                 //  </div>
    //                 );
    //             })}


    //         </Select>

    //     </FormControl>

    // </Grid>

    return (
        <Grid container class="categoryList">
            <Grid container justifyContent="space-between" alignItems="center">
                {/* Category Selecter */}


                {/* Category Search */}
                <Grid style={{ marginLeft: "1%" }} item xs={3}>
                    <input
                        style={{
                            paddingLeft: "10px",
                            width: "90%",
                            height: "56px",
                            fontSize: "16px",
                            border: "1px solid #c1c1c1",
                            borderRadius: "4px",
                        }}
                        type="text"
                        placeholder="Category Search ..."
                        onChange={handleCategorySearch}
                        value={categoryname}
                    />
                </Grid>

                {/* Part Search */}
                <Grid style={{ marginLeft: "-10%" }} item xs={3}>
                    <input
                        style={{
                            paddingLeft: "10px",
                            width: "100%",
                            height: "56px",
                            fontSize: "16px",
                            border: "1px solid #c1c1c1",
                            borderRadius: "4px",
                        }}
                        type="text"
                        placeholder="Part/ Description Search..."
                        onChange={handlePartSearch}
                        value={partnumber}
                    />
                </Grid>

                {/* Shopping Cart & Continue Button */}
                <Grid item sm={2}>
                    <StyledButton
                        color={'inherit'}
                        onClick={handleClick}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Badge badgeContent={getTotalItems()} color="error">
                            <AddShoppingCartIcon />
                        </Badge>
                    </StyledButton>
                    <Menu
                        anchorEl={menuAnchor}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 0,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem disabled>Your Cart</MenuItem>
                        <Divider></Divider>
                        <MenuItem>
                            <CartTable cart={cart} isEditable editRow={(row) => onClick(row)} />
                        </MenuItem>
                    </Menu>
                    <Button variant="contained" onClick={continueToAddress}>
                        Continue
                    </Button>
                </Grid>
                {/* </Grid> */}
            </Grid>
            <Grid container spacing={1} xs={12} sx={{ height: '80vh', marginTop: '10px' }}>
                <Grid item xs={3}>
                    <Grid style={{ height: "78vh" }} sx={{ overflowY: 'auto' }}>
                        <h2 style={{ display: "flex", justifyContent: "center" }}> Categories</h2>
                        <ul style={{ listStyle: 'none', padding: 0 }}>
                            {/* {console.log("CATEGORIES IN LIST: ", categories)} */}
                            {

                                tempCatagories.map((item, index) => {
                                    const corpCode_name = user.corpCodes[0];
                                    const image_name = item + '_' + corpCode_name + '.png';
                                    const distributionDomain = process.env.REACT_APP_CLOUD_FRONT;
                                    const formattedUrl = `https://${distributionDomain}/${image_name}`;
                                    const presignedUrl = formattedUrl.replace(/ /g, '%20');
                                    return (
                                        <li key={index} value={item}>
                                            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }} onClick={handleCategoryChange}
                                            >
                                                <img
                                                    src={presignedUrl}
                                                    alt="print-logo"
                                                    className="enlarge-image"
                                                    value={item}
                                                    style={{ height: '50px', width: '200px' }}
                                                />
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center", flexDirection: "column", textAlign: "center" }}>
                                                <p>{item}</p>
                                            </div>
                                        </li>
                                    );
                                })}
                        </ul>

                    </Grid>
                </Grid>
                <Grid item xs={9}
                    sx={{
                        height: '80vh',

                        '& .super-app-theme--true': {
                            bgcolor: (theme) =>
                                `${getBackgroundColor(
                                    theme.palette.warning.main,
                                    theme.palette.mode
                                )} !important`,
                            '&:hover': {
                                bgcolor: (theme) =>
                                    `${getHoverBackgroundColor(
                                        theme.palette.warning.main,
                                        theme.palette.mode
                                    )} !important`,
                            },
                        },
                        '& .super-app-theme--false': {},
                    }}
                >
                    <DataGrid
                        columnVisibilityModel={{
                            id: false,
                        }}
                        rows={displayData}
                        columns={columns}
                        rowCount={dataSize}
                        loading={loading}
                        disableSelectionOnClick={true}
                        getRowClassName={(params) =>
                            `super-app-theme--${params.row.isAddedToCart}`
                        }
                    />
                </Grid>
            </Grid>

            {/* Edit Part Modal */}
            <UpdatePartModal
                open={openEditModal}
                handleClose={() => setOpenEditModal(false)}
                row={editingPart}
                updateRow={(quantity, personalization) =>
                    updateRow(quantity, personalization)
                }
                deleteRow={deleteItemFromCart}
            />
        </Grid>
    );
};

export default withSnackbar(PlaceOrderNew);