import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { reducer } from './reducers';

let showReduxInDev = undefined;
if (
    !process.env.REACT_APP_ENVIRONMENT ||
    process.env.REACT_APP_ENVIRONMENT === 'development'
) {
    showReduxInDev = compose(
        applyMiddleware(thunk),
        window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : (f) => f
    );
}

export const store = createStore(reducer, showReduxInDev);
