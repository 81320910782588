import { Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import API from '../../common/axios/api';
import { cache } from '../../common/utils/cache';

const SalesOrderStatus = (props) => {
    const user = useSelector((state) => state.user);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Which BAQ we will access and options
    let api = new API('SIG_API_salesOrder');

    // Query for part data onload
    useEffect(() => {
        let filter = [];
        if (user !== null) {
            // load data by chunks
            // Get all jobs by customer id
            api.setCount()
                .setFilter(`BTCustomer_CustID eq '${user.customerId}'`)
                .setOrderby('OrderHed_OrderNum desc');

            cache(api)
                .then((response) => {
                    // console.log(response);
                    // I need to do this because I can't access `response.@odata.count`, wack i know
                    let countData = Object.values(response)[1];

                    // Map the data into the correct format
                    let formattedArr = response.data.value.map(
                        (value, index) => {
                            // Setting the status
                            let status = {
                                label: 'Open',
                                color: 'secondary',
                            };

                            if (!value.OrderHed_OpenOrder) {
                                status.label = 'Done';
                                status.color = 'success';
                            }

                            return {
                                id: index,
                                col1: value.OrderHed_OrderNum, // Sales Order
                                col2: new Date(value.OrderHed_OrderDate), // Date Entered
                                col3: value.OrderHed_PONum, // Purchase Order Number
                                col4: status.label, // Status
                            };
                        }
                    );

                    // Using lodash to remove sales order number duplicates
                    console.log();
                    setData(uniqBy(formattedArr, 'col1'));
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }, [user]);

    const columns = [
        {
            field: 'col1',
            headerName: 'Sales Order',
            width: 200,
            renderCell: (cellValue) => (
                <Link
                    underline="hover"
                    component={RouterLink}
                    to={`/vieworder?id=${cellValue.value}`}
                >
                    {cellValue.value}
                </Link>
            ),
        },
        {
            field: 'col2',
            headerName: 'Date Entered',
            width: 250,
            type: 'date',
        },
        {
            field: 'col3',
            headerName: 'Purchase Order Number',
            minWidth: 250,
            flex: 1,
        },
        {
            field: 'col4',
            headerName: 'Status',
            minWidth: 150,
        },
    ];

    // Search by customer
    return (
        <Grid container>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        underline="hover"
                        color="inherit"
                        component={RouterLink}
                        to="/"
                    >
                        Home
                    </Link>
                    <Typography color="text.primary">Orders</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12} sx={{ height: '70vh', marginTop: '10px' }}>
                <DataGrid
                    rows={data}
                    columns={columns}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    loading={loading}
                />
            </Grid>
        </Grid>
    );
};

export default SalesOrderStatus;
