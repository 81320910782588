import { Breadcrumbs, Grid, Link, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import API from '../../common/axios/api';
import { cache } from '../../common/utils/cache';

const InventoryStatus = (props) => {
    let history = useHistory();
    const user = useSelector((state) => state.user);
    // const [offset, setOffset] = useState(0);
    const [dataSize, setDataSize] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Which BAQ we will access and options
    let api = new API('SIG_API_CustomerInventory');
    api.setCount();

    // Query for part data onload
    useEffect(() => {
        let filter = [];
        if (user !== null) {
            if (user.role.toLowerCase() === 'distributor') {
                history.push('/home');
            }
            // Need to query by corp id's attached to the user
            user.corpCodes.forEach((corpCode) => {
                filter.push(`Part_CorpCode_c eq '${corpCode}'`);
            });
            // TODO: Change Back
            // user.corpCodes.forEach((corpCode) => {
            //     filter.push(`Part_CorpID_c eq ${corpCode}`);
            // });
            // Attach it to the api obj
            // Part_CorpCode_c eq 1234 or Part_CorpCode_c eq 5412
            if (filter.length > 0) {
                api.setFilter(filter.join(' or '));
            }

            // Cache Wrapper around API, this call might get triggered a bunch
            cache(api)
                .then((response) => {
                    console.log('From cache', response);
                    setLoading(false);
                    let countData = Object.values(response.data)[1];

                    setDataSize(countData);

                    formatData(response.data.value);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }, [user]);

    // Format the data we are getting into a format the Data Grid can read
    const formatData = (dataToFormat) => {
        // Map the data into the correct format
        let formattedArr = dataToFormat.map((value, index) => {
            return {
                id: index,
                col1: value.Part_PartNum,
                col2: value.Calculated_InStockQty,
                col3: value.Calculated_ShippedQty,
                col4: value.Calculated_WipQty,
                col5: value.Calculated_Committed,
                col6: value.Part_PartDescription,
                col7: value.Customer_Name,
            };
        });
        setData((data) => [...data, ...formattedArr]);
    };

    // We will have to format data we get from Epicore to fit this format
    const columns = [
        {
            field: 'col1',
            headerName: 'Part Number',
            width: 150,
            renderCell: (cellValue) => (
                <Link
                    underline="hover"
                    component={RouterLink}
                    to={`/part?id=${cellValue.value}`}
                >
                    {cellValue.value}
                </Link>
            ),
        },
        { field: 'col2', headerName: 'In stock', width: 150, type: 'number' },
        {
            field: 'col3',
            headerName: 'Shipped Yesterday',
            minWidth: 200,
            type: 'number',
        },
        { field: 'col4', headerName: 'WIP', minWidth: 110, type: 'number' },
        {
            field: 'col5',
            headerName: 'Commited',
            minWidth: 150,
            type: 'number',
        },
        {
            field: 'col7',
            headerName: 'Customer',
            minWidth: 150,
        },
        {
            field: 'col6',
            headerName: 'Description',
            minWidth: 150,
            flex: 1,
            renderCell: (params) => (
                <Tooltip title={params.value} placement="left">
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
    ];

    return (
        <Grid container>
            <Grid item>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        underline="hover"
                        color="inherit"
                        component={RouterLink}
                        to="/"
                    >
                        Home
                    </Link>
                    <Typography color="text.primary">Inventory</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12} sx={{ height: '80vh', marginTop: '10px' }}>
                <DataGrid
                    rows={data}
                    columns={columns}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    rowCount={dataSize}
                    loading={loading}
                />
            </Grid>
        </Grid>
    );
};

export default InventoryStatus;
