import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { styled } from '@mui/material/styles';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.signatureBlue,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableFooter = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: theme.palette.common.signatureBlue,
        color: theme.palette.common.black,
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const CartTable = ({ cart, isHistory, isEditable, editRow }) => {
    // Calculate total
    const [total, setTotal] = useState(0);
    const [open, setOpen] = useState(false);
    const [personalizationRow, setPersonalizationRow] = useState();

    const handleClickOpen = (row) => {
        setPersonalizationRow(row);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        let total = 0;
        if (cart !== undefined) {
            if (isHistory === undefined) {
                for (var i = 0; i < cart.length; i++) {
                    total += cart[i].price * cart[i].quantity;
                }
            } else {
                for (var j = 0; j < cart.length; j++) {
                    total += cart[j].Part_UnitPrice;
                }
            }
            setTotal(numeral(total).format('$0,0.00'));
        }
    }, [cart]);

    // Converts the numbers into a numeral (just a class that handles money conversion)
    const toPrice = (price, quantity) => {
        let p = numeral(price);
        let total = p.multiply(quantity);

        let result = numeral(total.value()).format('$0,0.00');
        return <p>{result}</p>;
    };

    const showDescWithPersonalization = (row) => {
        if (row.personalization !== undefined && row.personalization !== '') {
            return (
                <div>
                    {row.desc}
                    <br />

                    <Button onClick={() => handleClickOpen(row)}>
                        Show Personalization
                    </Button>
                </div>
            );
        } else {
            if (isHistory === undefined) {
                return <div>{row.category} <br></br> {row.desc}</div>;
            }
            return <div>{row.Part_PartDescription}</div>;
        }
    };

    if (cart !== undefined) {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="right">
                                Quantity
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                Part Description
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                Part Number
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                Price
                            </StyledTableCell>
                            {isEditable && 
                                <StyledTableCell sx={{ minWidth: 200 }} align="right">
                                    Edit Part
                                </StyledTableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cart.map((row, index) => (
                            <StyledTableRow key={index}>
                                {/* Quantity */}
                                <StyledTableCell align="right">
                                    {row.quantity}
                                </StyledTableCell>
                                {/* Personalization */}
                                <StyledTableCell align="left">
                                    {showDescWithPersonalization(row)}
                                </StyledTableCell>
                                {/* Part Num */}
                                <StyledTableCell align="right">
                                    {isHistory === undefined ? (
                                        <Link
                                            component={RouterLink}
                                            to={`/part?id=${row.partNum}`}
                                            target="_blank"
                                        >
                                            {row.partNum}
                                        </Link>
                                    ) : (
                                        <Link
                                            component={RouterLink}
                                            to={`/part?id=${row.Part_PartNum}`}
                                            target="_blank"
                                        >
                                            {row.Part_PartNum}
                                        </Link>
                                    )}
                                </StyledTableCell>
                                {/* Price */}
                                <StyledTableCell align="right">
                                    {isHistory === undefined
                                        ? toPrice.call(
                                              this,
                                              row.price,
                                              row.quantity
                                          )
                                        : toPrice.call(
                                              this,
                                              row.Part_UnitPrice,
                                              1
                                          )}
                                </StyledTableCell>
                                {isEditable &&
                                    <StyledTableCell align="right">
                                        <Button onClick={() => editRow(row)} variant="contained">
                                            Edit Part
                                        </Button>
                                    </StyledTableCell>
                                }
                            </StyledTableRow>
                        ))}

                        {/* TABLE TOTALS */}
                        <TableRow>
                            <StyledTableFooter></StyledTableFooter>
                            <StyledTableFooter></StyledTableFooter>
                            {isEditable && <StyledTableFooter></StyledTableFooter>}
                            <StyledTableFooter align="right">
                                <b>
                                    <u>Order Total</u>
                                </b>
                            </StyledTableFooter>
                            <StyledTableFooter>{total}</StyledTableFooter>
                        </TableRow>
                    </TableBody>
                </Table>

                {personalizationRow !== undefined && (
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {`Personalization:`}
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {personalizationRow.personalization}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
                )}
            </TableContainer>
        );
    } else {
        return <CircularProgress />;
    }
};

export default CartTable;
