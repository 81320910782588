import { Dialog, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UserDashboard from '../User/Dashboard';

const Home = (props) => {
    let location = useLocation();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successData, setSuccessData] = useState();

    // If a user completes an order will show confirmation screen
    useEffect(() => {
        if (location.state !== undefined) {
            let msg = location.state[0].message;
            setShowSuccessModal(true);
            setSuccessData(msg);
        }
    }, []);

    const handleClose = () => {
        setShowSuccessModal(false);
    };

    return (
        <>
            <UserDashboard />

            {successData !== undefined && (
                <Dialog onClose={handleClose} open={showSuccessModal}>
                    <DialogTitle>{successData}</DialogTitle>
                </Dialog>
            )}
        </>
    );
};

export default Home;
