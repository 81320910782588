// defines the object being stored by our redux store
const initialState = {
    user: null,
    order: null,
    orderCopy: null,
};

// reducers take a state and an action and return a new state. If we need to add
// more functions to perform on the state, add them here
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_USER':
            return { ...state, user: action.payload };
        case 'DELETE_USER':
            return { ...state, user: {} };
        case 'UPDATE_ORDER':
            return { ...state, order: action.payload };
        case 'DELETE_ORDER':
            return { ...state, order: null };
        case 'UPDATE_ORDER_COPY':
            return { ...state, orderCopy: action.payload };
        case 'DELETE_ORDER_COPY':
            return { ...state, orderCopy: null };
        default:
            return state;
    }
};
