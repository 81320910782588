import CreateIcon from '@mui/icons-material/Create';
import EmailIcon from '@mui/icons-material/Email';
import HistoryIcon from '@mui/icons-material/History';
import InventoryIcon from '@mui/icons-material/Inventory';
import API from '../../common/axios/api';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Link,
    Paper,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

const UserDashboard = (props) => {
    const user = useSelector((state) => state.user);
    const [tiles, setTiles] = useState([]);
   
  

    const [apiResponse, setApiResponse] = useState("");
    useEffect(() => {
        if (user.role.toLowerCase() === 'distributor') {
            setTiles([
                {
                    title: 'Place Graphic Order',
                    url: '/placeorder',
                    icon: (
                        <CreateIcon
                            sx={{
                                margin: '0 auto',
                                width: '100%',
                                fontSize: '65px',
                            }}
                        />
                    ),
                },
                {
                    title: 'View Order History',
                    url: '/orders',
                    icon: (
                        <HistoryIcon
                            sx={{
                                margin: '0 auto',
                                width: '100%',
                                fontSize: '65px',
                            }}
                        />
                    ),
                },
            ]);
        } 
        else {
            setTiles([
                {
                    title: 'Inventory Status',
                    url: '/inventory',
                    icon: (
                        <InventoryIcon
                            sx={{
                                margin: '0 auto',
                                width: '100%',
                                fontSize: '65px',
                            }}
                        />
                    ),
                },
                {
                    title: 'Place Graphic Order',
                    url: '/placeorder',
                    icon: (
                        <CreateIcon
                            sx={{
                                margin: '0 auto',
                                width: '100%',
                                fontSize: '65px',
                            }}
                        />
                    ),
                },
                {
                    title: 'View Order History',
                    url: '/orders',
                    icon: (
                        <HistoryIcon
                            sx={{
                                margin: '0 auto',
                                width: '100%',
                                fontSize: '65px',
                            }}
                        />
                    ),
                },
            ]);
        }
        if (user !== null) {
            let shipCodeBaq = new API('SIG_API_customerID_disclaimer');
            // Get Shipping Options
            shipCodeBaq
                .get()
                .then((response) => {

                    //response.data.value;
                    //setApiResponse("there is no data  available");
                    if(response.data !=null){
                       let message=response.data;
                        const message_split=message.split('|');
                        
                        setApiResponse(message_split);
                        
                        
                    }
                    else{
                    setApiResponse(null);
                    }
                    // console.log(response.data);
                })
                .catch((err) => console.log(err));
                
        }
        else
        {
            setApiResponse("")
         }       

    }, [user]);

    const renderActionItems = tiles.map((data, index) => (
        <Grid item key={index}>
            <Card variant="outlined">
                <CardContent>{data.icon}</CardContent>
                <CardActions>
                    <Button
                        component={RouterLink}
                        to={data.url}
                        size="large"
                        fullWidth
                    >
                        {data.title}
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    ));

    return (
        <div>
        <Grid container justifyContent="space-evenly">
            {/* Info text about how to use the website */}
            <Grid item mb={2}>
                <Paper sx={{ padding: '10px' }} elevation={3}>
                    <Typography variant="body2">
                        Welcome to Signature’s e-commerce site where you can
                        place orders, check stock, and view order history. Once
                        we receive your order, our team will contact you to
                        confirm availability, process payment, and make
                        arrangements for delivery and installation. Should you
                        need assistance, our team can be reached at
                        <Link href="tel:8003563235"> 800-356-3235 </Link>
                        to provide guidance.
                    </Typography>
                </Paper>
            </Grid>

            {renderActionItems}

            {/* Card for Emailing Support */}
            <Grid item>
                <Card variant="outlined">
                    <CardContent>
                        <EmailIcon
                            sx={{
                                margin: '0 auto',
                                width: '100%',
                                fontSize: '65px',
                            }}
                        />
                    </CardContent>
                    <CardActions>
                        <Button
                            component="a"
                            href="mailto:customerservice1@signaturegraph.com"
                            size="large"
                            fullWidth
                        >
                            Email Customer Service
                        </Button>
                    </CardActions>
                </Card>
            </Grid>


            <Grid item>
                <Card variant="outlined">
                    <CardContent>
                    {/* width="110" height="65" */}
                    <svg viewBox="0 0 200 200" width="180" height="65">
                    <image
                            xlinkHref={`${process.env.REACT_APP_URL}assets/ecommadmin.jpg`}
                            x="-20%"
                            y="-10%"
                            width="130%"
                            // transform="translate(-10%, -10%)"
                        />
                        <path fill="none" />
                        <path fill="currentColor" />
                        </svg>
                    
                    </CardContent>
                    <CardActions>
                        <Button
                            component="a"
                            href="mailto:ecommadmin@signaturegraph.com"
                            size="large"
                            fullWidth
                        >
                            Email Support
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            </Grid>
            {apiResponse && (
            <Grid container justifyContent="space-evenly"  marginTop={2}>
                
                
             
            <Grid item xs={12}  >
                <Paper sx={{ padding: '10px' ,textAlign: 'center'}} elevation={3} >

                
                {apiResponse.map((text, index) => (
            <Typography key={index} variant="body4">
              {text.split('|').map((line, index) => (
                <React.Fragment key={index}>
                  <strong>{line}</strong>
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          ))}

                    
                </Paper>
                </Grid>
              
              
             
                </Grid>
            )}


                </div>

    );
};


export default UserDashboard;
