import { apiInstance } from './config';

class API {
    /** @type {string} */
    BAQ;
    /** @type {(string| Array.)} */
    select;
    /** @type {string} */
    filter = '';
    /** @type {string} */
    orderby;
    /** @type {number} */
    top;
    /** @type {number} */
    skip;
    /** @type {boolean} */
    count;

    /**
     *
     * @param {string} BAQ The BAQ to query
     */
    constructor(BAQ) {
        this.BAQ = BAQ;
    }

    /**
     *
     * @param {string} [attributes] - List of attributes to select from the response
     * @returns
     */
    setSelect(attributes) {
        // Loops through the attributes adding them to a comma separated list
        this.select = attributes;
        return this;
    }

    /**
     * Refer to the https://docs.oasis-open.org/odata/odata/v4.01/odata-v4.01-part2-url-conventions.html#_Toc31360980
     * to learn how to filter via URL
     * @param {string} filter - A filter statement
     * @returns
     */
    setFilter(filter) {
        // TODO: might want to make helper functions for the filter
        if (filter === undefined) {
            this.filter = filter;
        } else {
            this.filter += filter;
        }
        return this;
    }

    /**
     *
     * @param {string} orderby - The name of the attribute you want to order by
     * @returns
     */
    setOrderby(orderby) {
        this.orderby = orderby;
        return this;
    }

    /**
     *
     * @param {number} top - The number of entries you want to get back
     * @returns
     */
    setTop(top) {
        this.top = top;
        return this;
    }

    /**
     *
     * @param {number} skip - Offset from the top, skip the number of entries from the top
     * @returns
     */
    setSkip(skip) {
        this.skip = skip;
        return this;
    }

    /**
     * If called will return a count of items in the response
     * @returns
     */
    setCount() {
        this.count = true;
        return this;
    }

    /**
     * Sends the request to the appropriate endpoint
     * @returns an axios response that you can then/catch
     */
    get() {
        let queryString = `${this.BAQ}/Data?`;
        let queryParams = [];

        if (typeof this.select !== 'undefined')
            queryParams.push(`$select=${this.select}`);
        if (this.filter !== '') queryParams.push(`$filter=${this.filter}`);
        if (typeof this.orderby !== 'undefined')
            queryParams.push(`$orderby=${this.orderby}`);
        if (typeof this.top !== 'undefined')
            queryParams.push(`$top=${this.top}`);
        if (typeof this.skip !== 'undefined')
            queryParams.push(`$skip=${this.skip}`);
        if (typeof this.count !== 'undefined')
            queryParams.push(`$count=${this.count}`);

        let result = apiInstance.get(
            `BaqSvc/${queryString}${queryParams.join('&')}`
        );
        return result;
    }

    post(request) {
        // We are posting to the Erp.BO.SalesOrderSvc/SalesOrders end point
        let result = apiInstance.post(
            'Erp.BO.SalesOrderSvc/SalesOrders',
            request
        );
        return result;
    }

    // This Handles Patching Personaliztion to an item in an order
    patchPersonalization(request, OrderNum, LineNum) {
        let result;
        // Need to separate them because AWS doesn't allow me to have this fancy endpoint
        if (
            !process.env.REACT_APP_ENVIRONMENT ||
            process.env.REACT_APP_ENVIRONMENT === 'development'
        ) {
            result = apiInstance.patch(
                `Erp.BO.SalesOrderSvc/OrderRels('SG100',${OrderNum},${LineNum},1)`,
                request
            );
        } else {
            result = apiInstance.patch(`Erp.BO.SalesOrderSvc`, request);
        }

        return result;
    }

    getImage(imageID) {
        let result = apiInstance.get(
            `Erp.BO.ImageSvc/Images?$filter=ImageID eq '${imageID}'`
        );

        return result;
    }

    reset() {
        this.select = undefined;
        this.filter = '';
        this.orderby = undefined;
        this.top = undefined;
        this.skip = undefined;
        this.count = undefined;
    }

    paramsToString() {
        let queryString = `${this.BAQ}/Data?`;
        let queryParams = [];

        if (this.filter !== '') queryParams.push(`$filter=${this.filter}`);

        return `BaqSvc/${queryString}${queryParams.join('&')}`;
    }

    print() {
        console.log('BAQ: ', this.BAQ);
        console.log('Select: ', this.select);
        console.log('Filter: ', this.filter);
        console.log('OrderBy: ', this.orderby);
        console.log('Top: ', this.top);
        console.log('Skip: ', this.skip);
        console.log('Count: ', this.count);
    }
}

export default API;
