import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker'
import {
    Breadcrumbs,
    CircularProgress,
    Divider,
    Grid,
    Link,
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import API from '../../common/axios/api';
import { getQueryParam } from '../../common/utils/util';
import CartAddress from './components/CartAddress';
import CartTable from './components/CartTable';
import ShippingStatus from './components/ShippingStatus';
import {parseISO} from 'date-fns';

const ViewSalesOrder = (props) => {
    const [cart, setCart] = useState();
    const [shippingAddr, setShippingAddr] = useState();
    const [billingAddr, setBillingAddr] = useState();
    const [shippingDetails, setShippingDetails] = useState();
    const [orderInfo, setOrderInfo] = useState();

    // Getting user data
    const user = useSelector((state) => state.user);

    // Getting Parameter ID
    const location = useLocation();
    let id = getQueryParam('id', location);

    useEffect(() => {
        // Which BAQ we will access and options
        let api = new API('SIG_API_salesOrder');

        if (user !== null) {
            // Get all jobs by customer id
            api.setCount().setFilter(
                `BTCustomer_CustID eq '${user.customerId}' and OrderHed_OrderNum eq ${id}`
            );
            api.setOrderby('OrderRel_OrderLine');

            api.get()
                .then((response) => {
                    let value = response.data.value;

                    // Get Data for the Table
                    // Map the data into the correct format
                    let cartFormat = value.map((value, index) => {
                        return {
                            quantity: value.OrderDtl_SellingQuantity,
                            Part_PartDescription: value.OrderDtl_LineDesc,
                            Part_PartNum: value.OrderDtl_PartNum,
                            Part_UnitPrice: value.OrderDtl_DocExtPriceDtl,
                        };
                    });
                    cartFormat.push({
                        quantity: '',
                        Part_PartDescription: 'TAX',
                        Part_PartNum: '',
                        Part_UnitPrice: value[0].OrderHed_DocTotalTax,
                    });

                    setCart(cartFormat);

                    // Get Order Information
                    let salesEntry = value[0];
                    setOrderInfo({
                        Customer_Name: salesEntry.Customer_Name,
                        OrderDate: parseISO(salesEntry.OrderHed_OrderDate),
                        PO_Num: salesEntry.OrderHed_PONum,
                    });

                    // Get Tracking info
                    setShippingDetails({
                        shipCode: salesEntry.OrderHed_ShipViaCode,
                        shipDesc: salesEntry.ShipVia_Description,
                        shipTrackingNo: salesEntry.ShipHead_TrackingNumber,
                    });

                    // Get Shipping Address Information
                    setShippingAddr({
                        ShipTo_Name: salesEntry.ShipTo_Name,
                        ShipTo_Attention: '', // Still dont have
                        ShipTo_Address1: salesEntry.ShipTo_Address1,
                        ShipTo_Address2: salesEntry.ShipTo_Address2,
                        ShipTo_Address3: salesEntry.ShipTo_Address3,
                        ShipTo_City: salesEntry.ShipTo_City,
                        ShipTo_State: salesEntry.ShipTo_State,
                        ShipTo_ZIP: salesEntry.ShipTo_ZIP,

                        // Never got the data so I am 'zeroing it out'
                        ShipTo_PhoneNum: '',
                        ShipTo_FaxNum: '',
                        ShipTo_Email: '',
                    });

                    // Get the Billing Address Information
                    setBillingAddr({
                        Customer_BTName: salesEntry.Customer_Name,
                        Customer_BTAddress1: salesEntry.Customer_Address1,
                        Customer_BTAddress2: salesEntry.Customer_Address2,
                        Customer_BTAddress3: salesEntry.Customer_Address3,
                        Customer_BTCity: salesEntry.Customer_City,
                        Customer_BTState: salesEntry.Customer_State,
                        Customer_BTZip: salesEntry.Customer_Zip,

                        // Never got the data so I am 'zeroing it out'
                        Customer_BTPhoneNum: '',
                        Customer_BTFaxNum: '',
                        Customer_BTEmail: '',
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [user]);

    return (
        <Grid container spacing={2}>
            {/* BreadCrumbs */}
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        underline="hover"
                        color="inherit"
                        component={RouterLink}
                        to="/"
                    >
                        Home
                    </Link>

                    <Link
                        underline="hover"
                        color="inherit"
                        component={RouterLink}
                        to="/orders"
                    >
                        Orders
                    </Link>

                    <Typography color="text.primary">Order #{id}</Typography>
                </Breadcrumbs>
            </Grid>

            <Grid item xs={6} mb={6}>
                <Grid container direction="column">
                    {/* Show what is in the cart */}
                    <Grid item>
                        <CartTable cart={cart} isHistory={true} />
                    </Grid>

                    {/* Gather more information before sendin the order */}
                    <Paper
                        elevation={3}
                        sx={{ backgroundColor: 'Snow', p: 1, mt: 3 }}
                    >
                        {/* Section Title */}
                        <Grid item container xs={12} justifyContent="center">
                            <Grid item xs={12}>
                                <Typography variant="h5" align="center">
                                    Order Information
                                </Typography>
                                <Divider />
                            </Grid>

                            {orderInfo !== undefined &&
                            shippingDetails !== undefined ? (
                                <div>
                                    {/* Customer Name */}
                                    <Grid item mt={1}>
                                        <TextField
                                            sx={{ width: '33ch' }}
                                            fullWidth
                                            id="CustomerName"
                                            name="CustomerName"
                                            label="Customer Name"
                                            disabled
                                            value={orderInfo.Customer_Name}
                                        />
                                    </Grid>

                                    {/* Date Requested */}
                                    <Grid item mt={1}>
                                        <DesktopDatePicker
                                            foramt="MM/dd/yyyy"
                                            id="shipDate"
                                            name="shipDate"
                                            label="Order Date"
                                            value={orderInfo.OrderDate}
                                            disabled
                                            onChange={() => {}}
                                            textField={(params) => (
                                                <TextField
                                                    {...params}
                                                    disabled
                                                    sx={{ width: '33ch' }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    {/* PO Num */}
                                    <Grid item mt={1}>
                                        <TextField
                                            sx={{ width: '33ch' }}
                                            fullWidth
                                            id="poNumber"
                                            name="poNumber"
                                            label="P.O. Number"
                                            disabled
                                            value={orderInfo.PO_Num}
                                        />
                                    </Grid>

                                    {/* Shipping */}
                                    <Grid item mt={3}>
                                        <TextField
                                            sx={{ width: '33ch' }}
                                            fullWidth
                                            id="Shipping"
                                            name="Shipping"
                                            label="Shipping"
                                            disabled
                                            value={shippingDetails.shipDesc}
                                        />
                                    </Grid>

                                    {/* Shipping btn */}
                                    <Grid item>
                                        <ShippingStatus
                                            info={shippingDetails}
                                        />
                                    </Grid>
                                </div>
                            ) : (
                                <CircularProgress />
                            )}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
                <Grid container direction="column">
                    <Grid item mt={2}>
                        <CartAddress
                            shippingAddr={shippingAddr}
                            billingAddr={billingAddr}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ViewSalesOrder;
