import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { withSnackbar } from '../../../common/utils/SnackBarHOC';
import { styled } from '@mui/material/styles';

const Personalization = styled('div')(({ theme }) => ({
    color: '#747474',
    marginTop: '20px',
}));

const UpdatePartModal = ({ open, handleClose, row, updateRow, deleteRow }) => {
    const [quantity, setQuantity] = useState('');
    const [personalization, setPersonalization] = useState('');
    const [error, setError] = useState({});

    useEffect(() => {
        if (row !== undefined) {
            setQuantity(row.quantity);
            setPersonalization(row.personalization);
        }
        // console.log("Row/part added to cart", row.isAddedToCart);
    }, [row]);

    const handleQuantityChange = (event) => {
        let q = event.target.value;
        // console.log(q);
        if (!isNaN(q)) {
            if (q === '') {
                setQuantity(q);
            } else {
                let qNum = parseInt(q);
                // console.log('qnum', qNum);
                if (qNum < 0) {
                    setError({
                        ...error,
                        quantity: {
                            message: 'Quantity must greater than zero!',
                        },
                    });
                } else {
                    let err = error;
                    delete err.quantity;
                    setError(err);
                    setQuantity(qNum);
                }
            }
        } else {
            setError({
                ...error,
                quantity: {
                    message: 'Quantity must be a number!',
                },
            });
        }
    };

    const handlePersonalizationChange = (event) => {
            setPersonalization(event.target.value);       
    };

    // When the user done entering in their information and click save we tell the parent
    const saveCartData = () => {
        if (quantity === '' || quantity === 0 || quantity === undefined ) {
            setError({
                ...error,
                quantity: {
                    message: 'Quantity is mandatory',
                },
            });
           //  handleClose();
        } 
         else if(row.isPersonalizable && personalization ==='')
        { 
            setError({
                ...error,
                personalization: {
                    message: 'Personalization is mandatory',
                },
            });
         //  console.log("error")
        }
        else {
            // console.log(quantity, personalization);
         
          
                let err = error;
                    delete err.personalization;
                    setError(err);
                    //delete err.quantity;
                   // setError(err)
            updateRow(quantity, personalization);
           
          
        }
    };

    if (row !== undefined) {
        return (
            <Grid>
                <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
                    <DialogTitle>Add Part: {row.partNum}</DialogTitle>
                    <DialogContent>
                        <DialogContentText></DialogContentText>

                        {/* Quantity */}
                        <TextField
                            autoFocus
                            margin="dense"
                            id="quantity"
                            label="Quantity"
                            type="text"
                            fullWidth
                            variant="filled"
                            value={quantity}
                            onChange={handleQuantityChange}
                            error={error.quantity !== undefined}
                            helperText={
                                error.quantity !== undefined
                                    ? error.quantity.message
                                    : ''
                            }
                        />

                        {/* Personalization if available for the part */}
                        {row.isPersonalizable && (
                            <Personalization>
                                <Typography variant="subtitle2">{row.personalizationPrompt}</Typography>
                                <TextField
                                    margin="dense"
                                    id="personalization"
                                    label="Personalization"
                                    type="text"
                                    fullWidth
                                    variant="filled"
                                    multiline
                                    rows={4}
                                    value={personalization}
                                    onChange={handlePersonalizationChange}
                                    error={error.personalization !== undefined}
                                helperText={
                                error.personalization !== undefined
                                    ? error.personalization.message
                                    : ''
                            }
                                />
                            </Personalization>
                        )}
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        {(row.isAddedToCart)
                            ? <div>
                                <Button onClick={deleteRow} variant="outlined" color="error">Delete Item</Button>
                                <Button onClick={saveCartData} variant="outlined">Update Item</Button>
                            </div>

                            : <Button onClick={saveCartData} variant="outlined">Add to Cart</Button>
                        }
                        
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    }
    return <></>;
};

export default withSnackbar(UpdatePartModal);
// export { UpdatePartModal };
