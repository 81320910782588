import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import { Container, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import 'react-image-lightbox/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Footer from './common/components/Footer';
// Importing common components
import Header from './common/components/Header';
import { deleteUserInState, saveUserInState } from './common/redux/actions';
import { routes, RouteWithSubRoutes } from './routes';
// import Routes from './Routes.jsx';
import { Theme } from './theme';

const App = (props) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    // When the app first loads, try to see if the user is already logged in
    useEffect(() => {
        Auth.currentAuthenticatedUser({ bypassCache: true })
            .then((user) => {
                // console.log(user);

                // Pull the corp ids from the attributes and convert them to an array of ints
                const userAttributes = Object(user.attributes);

                const miscData = JSON.parse(userAttributes['custom:miscData']);
                const corpCodes = miscData['CorpCodes'] // TODO: Change to CorpCodes
                    .split(',')
                    .map((code) => code);
                const customerId = miscData['CustomerID'];
                const role = miscData['Role'];

                dispatch(
                    saveUserInState({
                        ...user,
                        customerId: customerId,
                        corpCodes: corpCodes,
                        role: role !== undefined ? role : 'Customer',
                    })
                );
            })
            .catch((err) => {
                console.log(err);

                if (user !== null && Object.keys(user).length !== 0) {
                    // Usually means that the user profile is not present, so best if we sign them out
                    dispatch(deleteUserInState());
                    window.location.reload(false);
                }
            });
    }, []);


    

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={Theme}>
                <CssBaseline />

                <Router>
                    <Header />

                    {/* Main Page Content */}
                    <Container
                        maxWidth="xl"
                        sx={{
                            padding: '10px',
                            marginTop: '75px',
                            marginBottom: '75px',
                        }}
                    >
                        {/* Where the routes are rendered from config */}
                        <main sx={{ flex: 1 }}>
                            <Switch>
                                {routes.map((route, i) => (
                                    <RouteWithSubRoutes key={i} {...route} />
                                ))}
                            </Switch>
                        </main>
                    </Container>

                    <Footer />
                </Router>
            </ThemeProvider>
        </LocalizationProvider>
    );
};
export default App;
