import { AppBar, Box, Grid, Link, Toolbar,Button } from '@mui/material';
import React from 'react';

const Footer = (props) => {
    return (
        <AppBar
            position="fixed"
            color="primary"
            sx={{ top: 'auto', bottom: 0 }}
        >
            <Toolbar>
                <Box sx={{ flexGrow: 1 }} />
                <Grid container justifyContent="center">
                    <Grid item>
                        <Link
                            href={process.env.REACT_APP_RETURN_TO_SIGNATURE_URL}
                            target="_top"
                            rel="noopener"
                        >
                            Return to Signature’s website
                        </Link>
                    </Grid>
                    
                    <Button
                            style={{ textTransform: 'none' ,display: 'inline-block',position: 'absolute', right: 50 ,textDecoration: 'underline', textAlign: 'top',padding:'0px'}}
                            component="a"
                            href="mailto:ecommadmin@signaturegraph.com"
                            size="Large"
                            target="_top"
                            rel="noopener"
                            
                            
                        >
                            Email Support
                    </Button>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default Footer;
