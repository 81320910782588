import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const CartAddress = ({ shippingAddr, billingAddr }) => {
    const [formatShip, setFormatShip] = useState('');
    const [formatBill, setFormatBill] = useState('');

    useEffect(() => {
        if (shippingAddr !== undefined && billingAddr !== undefined) {
            // Format the Shipping Address
            let formatS = [];
            formatS.push(shippingAddr.ShipTo_Name); // Name
            if (shippingAddr.ShipTo_Attention !== '')
                formatS.push(`ATTN: ${shippingAddr.ShipTo_Attention}`); // Attention
            formatS.push(shippingAddr.ShipTo_Address1); // Address1
            formatS.push(shippingAddr.ShipTo_Address2); // Address2
            formatS.push(shippingAddr.ShipTo_Address3); // Address3
            // City, State, Zip
            formatS.push(
                `${shippingAddr.ShipTo_City}, ${shippingAddr.ShipTo_State}, ${shippingAddr.ShipTo_ZIP}`
            );
            if (shippingAddr.ShipTo_PhoneNum !== '')
                // Phone
                formatS.push(`Phone: ${shippingAddr.ShipTo_PhoneNum}`);
            if (shippingAddr.ShipTo_FaxNum !== '')
                // Fax
                formatS.push(`Fax: ${shippingAddr.ShipTo_FaxNum}`);
            if (shippingAddr.ShipTo_Email !== '')
                // Email
                formatS.push(`Email: ${shippingAddr.ShipTo_Email}`);

            setFormatShip(formatS.filter((attr) => attr !== '').join('\n'));

            //----------------------------------------------------------------
            // Format the Billing Address

            let formatB = [];
            formatB.push(billingAddr.Customer_BTName); // Name
            formatB.push(billingAddr.Customer_BTAddress1); // Address1
            formatB.push(billingAddr.Customer_BTAddress2); // Address2
            formatB.push(billingAddr.Customer_BTAddress3); // Address3
            // City, State, Zip
            formatB.push(
                `${billingAddr.Customer_BTCity}, ${billingAddr.Customer_BTState}, ${billingAddr.Customer_BTZip}`
            );
            if (billingAddr.Customer_BTPhoneNum !== '')
                formatB.push(`Phone: ${billingAddr.Customer_BTPhoneNum}`); // Phone
            if (billingAddr.Customer_BTFaxNum !== '')
                formatB.push(`Fax: ${billingAddr.Customer_BTFaxNum}`); // Fax
            if (billingAddr.Customer_BTEmail !== '')
                formatB.push(`Email: ${billingAddr.Customer_BTEmail}`); // Email

            setFormatBill(formatB.filter((attr) => attr !== '').join('\n'));
        }
    }, [shippingAddr, billingAddr]);

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 0.5,
            }}
        />
    );

    if (shippingAddr !== undefined && billingAddr !== undefined) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    '& > :not(style)': {
                        backgroundColor: 'rgba(145,201,230,.1)',
                        boxShadow:
                            'inset 0rem 0rem .1rem .1rem rgba(102, 102, 102,1)',
                    },
                }}
            >
                <Paper elevation={3}>
                    <Typography m={3}>Bill To:</Typography>
                    <Typography
                        mt={2}
                        ml={4}
                        mr={3}
                        mb={2}
                        variant="h6"
                        sx={{ whiteSpace: 'pre-line' }}
                    >
                        {formatBill}
                    </Typography>
                    <ColoredLine mt={5} color="black" />
                    <Typography m={3}>Ship To:</Typography>
                    <Typography
                        mt={2}
                        ml={4}
                        mr={3}
                        mb={2}
                        variant="h6"
                        sx={{ whiteSpace: 'pre-line' }}
                    >
                        {formatShip}
                    </Typography>
                </Paper>
            </Box>
        );
    } else {
        return <CircularProgress />;
    }
};

export default CartAddress;
