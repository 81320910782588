/* 
    Check local storage for the BAQ specific cache
        1. if empty - make the request, store and return it
        2. if not empty
            3. run the same request with a top of 1 and count set - check if requested count === cache count
                4. if equal - serve the cache
                5. else - re request data, store and return it
*/

// Not a default cache perse, but we use local storage to actually store the data
export const cache = (apiObject) => {
    let cacheName = apiObject.paramsToString(); // Name it the same as the BAQ
    apiObject.setCount(true);
    let retry = 0;

    const store = localStorage.getItem(cacheName);

    const myPromise = new Promise((resolve, reject) => {
        apiObject
            .get()
            .then((response) => {
                // Store in cache
                localStorage.setItem(cacheName, JSON.stringify(response));
                // resolve promise
                resolve(response);
            })
            .catch((err) => {
                if (err.response != null && err.response.status === 502) {
                    apiObject
                        .get()
                        .then((response) => {
                            // Store in cache
                            localStorage.setItem(
                                cacheName,
                                JSON.stringify(response)
                            );
                            // resolve promise
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                } else {
                    console.log(err.message);
                }
                reject(err);
            });
    });
    return myPromise;

    // TODO: Add in later
    // if (store === null) {
    //     // 1.
    //     const myPromise = new Promise((resolve, reject) => {
    //         apiObject
    //             .get()
    //             .then((response) => {
    //                 // Store in cache
    //                 localStorage.setItem(cacheName, JSON.stringify(response));
    //                 // resolve promise
    //                 resolve(response);
    //             })
    //             .catch((err) => {
    //                 reject(err);
    //             });
    //     });

    //     return myPromise;
    // } else {
    //     // 2.
    //     const myPromise = new Promise((resolve, reject) => {
    //         let cachedData = JSON.parse(store);
    //         apiObject.setTop(1); // Get a tiny amount of data, used to invalidate the cache
    //         // 3.
    //         apiObject
    //             .get()
    //             .then((response) => {
    //                 // I need to do this because I can't access `response.@odata.count`, wack i know
    //                 let currCount = Object.values(response.data)[1];
    //                 let cachedCount = Object.values(cachedData.data)[1];

    //                 if (currCount === cachedCount) {
    //                     // 4.
    //                     resolve(cachedData);
    //                 } else {
    //                     // 5.
    //                     apiObject.setTop(undefined);
    //                     apiObject
    //                         .get()
    //                         .then((response) => {
    //                             // Remove the current cached data
    //                             localStorage.removeItem(cacheName);

    //                             // Update the item
    //                             localStorage.setItem(
    //                                 cacheName,
    //                                 JSON.stringify(response)
    //                             );
    //                             // resolve promise
    //                             resolve(response);
    //                         })
    //                         .catch((err) => {
    //                             reject(err);
    //                         });
    //                 }
    //             })
    //             .catch((err) => {
    //                 reject(err);
    //             });
    //     });

    //     return myPromise;
    // }
};
