import { Button, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { withSnackbar } from "../../common/utils/SnackBarHOC";

const Login = ({ snackbarShowMessage }) => {
  const location = useLocation();

  const history = useHistory();

  const user = useSelector((state) => state.user);

  const [pastLocation, setPastLocation] = useState(null);

  useEffect(() => {
    if (location.state !== undefined) {
      if (location.state.message !== undefined) {
        snackbarShowMessage(`Error: ${location.state.message}`, "error", 5000);
      }

      if (location.state.pastLocation !== undefined) {
        setPastLocation(location.state.pastLocation);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (user !== null) {
      if (pastLocation !== null) {
        history.push(
          `${pastLocation.pathname}${pastLocation.search}${pastLocation.hash}`
        );
      }
    }
  }, [user]);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  if (user === null) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
    
       
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div
            style={{
              position: "relative",

              zIndex: 1,
            }}
          >
            <Paper
              sx={{
                boxShadow: "none",

                backgroundColor: "transparent",
              }}
              style={{
                display: "flex",

                flexDirection: "column",

                justifyContent: "center",

                alignItems: "center",

                marginBottom: "80px",
              }}
            >
              <div
                style={{
                  width: "60vh",

                  height: "55vh",

                  borderRadius: "80px",

                  border: "8px solid #A6A6A6",

                  display: "flex",

                  flexDirection: "column",

                  justifyContent: "center",

                  alignItems: "center",

                  background: "white",

                  padding: "10px",

                  overflow: "hidden",
                }}
              >
                <img
                  src="https://ddm90ybgne3qd.cloudfront.net/Sig_Logo/sigLogo.png"
                  alt="Image"
                  style={{
                    width: "50vh",

                    marginBottom: "10px",
                  }}
                />

                <Button
                  variant="contained"
                  href={process.env.REACT_APP_COGNITO_SIGN_IN_URL}
                  style={{
                    width: "25vh",

                    height: "6vh",

                    borderRadius: "10px",

                    fontSize: "2vh",

                    color: "#fff",

                    backgroundColor: "#1876D1",

                    marginBottom: "40px",

                    marginTop: "10px",
                  }}
                >
                  Click to sign in
                </Button>

                <Button
                  variant="contained"
                  href="mailto:ecomm@signaturegraph.com?bcc=ecommadmin%40signaturegraph.com&subject=eCommerce%20Access%20Request&body=First%20Name%3A%0D%0ALast%20Name%3A%0D%0AEmail%3A%0D%0ACompany%20Name%3A%0D%0ABilling%20Address%3A%0D%0APhone%3A"
                  style={{
                    width: "35vh",

                    height: "6vh",

                    fontSize: "2vh",

                    borderRadius: "10px",

                    color: "#fff",

                    backgroundColor: "#1876D1",

                    marginBottom: "10px",
                  }}
                >
                  Click to Request Account
                </Button>

                <Typography
                  variant="body2"
                  component="p"
                  style={{
                    textAlign: "center",

                    color: "#0166A6",

                    fontSize: "2.5vh",

                    wordWrap: "break-word",

                    padding: "2px",
                  }}
                >
                  When requesting an account, please include your first and last
                  name, company name, billing address, phone number, and email
                  address.
                </Typography>
              </div>
            </Paper>
          </div>

          <img
            src="https://ddm90ybgne3qd.cloudfront.net/Sig_Logo/Hero-Homepage-Img_ECOMM2.jpg"
            alt="Background"
            style={{
              position: "absolute",

              top: 50,

              left: 0,

              width: "100%",

              height: "calc(65% - 60px)",

              objectFit: "cover",

              zIndex: 0,
            }}
          />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <div>
        <Redirect to="/home" />
      </div>
    );
  }
};

export default withSnackbar(Login);