import {
    Box,
    CircularProgress,
    Grid,
    ImageList,
    ImageListItem,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import urlencode from 'urlencode';
import API from '../../../common/axios/api';

// https://sig-epicor-app2/Test/api/v2/odata/SG100/Erp.BO.ImageSvc/Images?%24filter=ImageID%20eq%20'00127367'
const PartImage = ({ part }) => {
    const [imageList, setImageList] = useState([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [selectedImage, setSelectedImage] = useState(null);
    const [openLightBox, setOpenLightBox] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let api = new API('Image');
        if (part.Image_ImageID !== null) {
            api.getImage(urlencode(part.Image_ImageID))
                .then((response) => {
                    console.log('Image', response);
                    setImageList(response.data.value);
                    setSelectedImage(response.data.value[0]);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, []);

    const viewLargerImage = (index) => {
        setSelectedImage(imageList[index]);
        setSelectedImageIndex(index);
    };

    if (imageList !== undefined && imageList.length > 0 && !loading) {
        return (
            <Grid container>
                {/* Image List */}
                <Grid item md={2}>
                    <Box
                        sx={{
                            width: 100,
                            height: 450,
                            overflowY: 'scroll',
                        }}
                    >
                        <ImageList cols={1} gap={2}>
                            {imageList.map((item, index) => (
                                <ImageListItem
                                    key={item.ImageFileName}
                                    onClick={() => viewLargerImage(index)}
                                >
                                    <img
                                        src={`data:image/png;base64,${item.ImageContent}`}
                                        srcSet={`data:image/png;base64,${item.ImageContent}`}
                                        alt={item.ImageFileName}
                                        loading="lazy"
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Box>
                </Grid>
                {/* Larger Image */}
                <Grid item md={10}>
                    {selectedImage !== null && imageList.length > 0 && (
                        <Box onClick={() => setOpenLightBox(true)}>
                            <img
                                style={{ objectFit: 'contain' }}
                                height="450px"
                                width="100%"
                                src={`data:image/png;base64, ${selectedImage.ImageContent}`}
                                alt={selectedImage.ImageFileName}
                            />
                        </Box>
                    )}
                </Grid>

                {/* Where the lightbox stuff goes */}
                {openLightBox && (
                    <Lightbox
                        mainSrc={`data:image/png;base64,  ${imageList[selectedImageIndex].ImageContent}`}
                        nextSrc={`data:image/png;base64,  ${imageList[0].ImageContent}`}
                        prevSrc={`data:image/png;base64,  ${imageList[0].ImageContent}`}
                        onCloseRequest={() => setOpenLightBox(false)}
                        onMovePrevRequest={() => {
                            var ndx =
                                (selectedImageIndex + imageList.length - 1) %
                                imageList.length;
                            setSelectedImageIndex(ndx);
                            setSelectedImage(imageList[ndx]);
                        }}
                        onMoveNextRequest={() => {
                            var ndx =
                                (selectedImageIndex + 1) % imageList.length;
                            setSelectedImageIndex(ndx);
                            setSelectedImage(imageList[ndx]);
                        }}
                    />
                )}
            </Grid>
        );
    } else if (loading === true) {
        return (
            <Grid container justifyContent="center">
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Typography variant="body2" align="center">
                No image available
            </Typography>
        );
    }
};

export default PartImage;
